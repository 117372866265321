export class Cel {
    _id;
    _label;
    _werkblad;
    _instantieVan;
    _groepId;

     constructor(){
         this._id = Cel.newGuid();
         this._label = "";
         this._werkblad = null;
     }

    getId(){
        return this._id;
    }

    getLabel(){
        return this._label;
    }
    setLabel(value){
        this._label = value;
    }

    getWerkblad(){
        return this._werkblad;
    }
    setWerkblad(value){
        this._werkblad = value;
    }

     /// <summary>
     ///	newGuid maakt een guid aan als ID van de cel
     /// </summary>
     /// <return>
     ///	Een Guid voor het id van de cel
     /// </return>
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}