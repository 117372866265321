import {canvasInstantie} from './canvas';
import StyleSheet from './style-sheet';
import attributen from '../assets/objecten.json';
import personen from '../assets/personen.json';
import bewegingen from '../assets/bewegingen.json';
import { Attribuut } from './cellen/attribuut';
import { Lijn } from './cellen/lijn';
import { Groep } from './cellen/groep';

const mx = require('mxgraph')({
    mxBasePath: '/lib/mxgraph'
});

export class WerkVeld {
    _bladBreedte;
    _bladHoogte;
    _styleSheet;
    _cellen = new Array(); // houdt een lijst bij met alle geplaatste /gekopieërde attributen, bewegingen etc
    _groepen = new Array();
    _htmlElementStyles; // houdt een map bij met de naam van een attribuut als key en een style als value
    _celIDs; // houdt een map bij met de attribuut id als key en de bijbehorende mxCell als value
    _styleCounter;

    constructor(){
        this._styleSheet = new StyleSheet();
        this._celIDs = new Map();
        this._htmlElementStyles = new Map();
        this._styleCounter = 0;
        
        // zet de scroll x en y standaard op 0
        mx.mxUtils.getDocumentScrollOrigin = function()
        {
                var x = 0;
                var y = 0;
                return new mx.mxPoint(x, y);
        };
    }

    /// <summary>
    ///	Sla een attribuut op met referentie naar de mxCell
    /// </summary>
    /// <param name='attribuut'>Het op te slaan attribuut</param>
    /// <param name='mxCell'>Mxcell die bij het attribuut hoort</param>

    saveAttribuut(attribuut, mxcell){
        werkveldInstantie._cellen.push(attribuut);
        werkveldInstantie._celIDs.set(attribuut._id, mxcell);
    }
    
    /// <summary>
    ///	Maak het het tekstvlak sleepbaar
    /// </summary>
    /// <param name='container'>De html container van het tekstvlak</param>
    maakTekstvakSleepbaar(container){
        var plaatsAtrribuutFunctie = canvasInstantie.maakPlaatsAttribuut(
            'stijl_tekstvlak', {naam: "tekstvlak", afbeeldingpad: null, grootte: [100.0,50.0]}, null, "Tekst");
        mx.mxUtils.makeDraggable(container, canvasInstantie._graph, plaatsAtrribuutFunctie, null);
    }

    /// <summary>
    ///	Roept per container het sleepbaar maken aan.
    /// </summary>
    /// <param name='containers'> HTML containers die sleepbaar gemaakt moeten worden</param>
    maakSleepbaar(containers) {
        for(var i =0;i < containers.length;i++){
            var htmlContainerElement = containers[i];
            var htmlAfbeeldingElement = htmlContainerElement.childNodes[0];

            this.maakAttribuutSleepbaar(htmlContainerElement, htmlAfbeeldingElement);
        }
    }

    /// <summary>
    ///	Functie die een dragplaceholder aan maakt en een html element (van een attribuut, persoon etc) sleepbaar maakt
    /// </summary>
    /// <param name='htmlContainerElement'>De html container</param>
    /// <param name='htmlAfbeeldingElement'>De img element die in de container zit</param>
    maakAttribuutSleepbaar(htmlContainerElement, htmlAfbeeldingElement) {

        var dragPlaceHolder = htmlAfbeeldingElement.cloneNode(true);
        dragPlaceHolder.style.width = "30px";
        dragPlaceHolder.style.height = "30px";
        dragPlaceHolder.style.backgroundImage = `/img/icons/${htmlAfbeeldingElement.getAttribute("src")}`;

        var plaatsAtrribuutFunctie = canvasInstantie.maakPlaatsAttribuut(
            this._htmlElementStyles.get(htmlAfbeeldingElement.getAttribute("name")), 
            this.getJsonObject((htmlAfbeeldingElement.getAttribute("name"))).returnItem,
            this.getJsonObject((htmlAfbeeldingElement.getAttribute("name"))).categorieName, null);
        mx.mxUtils.makeDraggable(htmlContainerElement, canvasInstantie._graph, plaatsAtrribuutFunctie, dragPlaceHolder);
    }

    /// <summary>
    ///	Loopt door de json bestanden heen om te zoeken naar het json object wat bij die naam hoort
    /// </summary>
    /// <param name='naam'> De naam die in het json object staat</param>
    /// <return>
    ///	het json object
    /// </return>
    getJsonObject(naam) {
        var returnItem;
        var Categorie;
        if (naam == 'tekstvlak'){
            return {
                returnItem: {naam: naam, afbeeldingpad: null, grootte: [100.0,50.0]},
                categorieName: 'attributen'
            }
        }
        if (naam == 'lege-activiteit'){
            return {
                returnItem: {naam: naam, afbeeldingpad: null, grootte: [200.0,200.0]},
                categorieName: 'attributen'
            }
        }
        attributen.groepen.forEach(element => {
            element.objecten.forEach(item => {
                if (item.naam == naam){
                    returnItem = item;
                    Categorie = "attributen";
                }
            });
        });
        personen.personen.forEach(element => {
            element.objecten.forEach(item => {
                if (item.naam == naam){
                    returnItem = item;
                    Categorie = "personen";
                }
            });
        })
        bewegingen.bewegingen.forEach(element => {
            element.objecten.forEach(item => {
                if (item.naam == naam){
                    returnItem = item;
                    Categorie = "bewegingen";
                }
            });
        })
        return {
            returnItem: returnItem,
            categorieName: Categorie
        };
    }

    /// <summary>
    ///	Loopt door alle Json bestanden heen en maakt een stijl aan voor het json object.
    /// Wordt gedaan bij het opstarten van de applicatie
    /// </summary>
    zetAttribuutStijlen(){
        attributen.groepen.forEach(element => {
            element.objecten.forEach(item => {
                this.maakAttribuutStijl(item.naam, item.afbeeldingpad, `stijl_attribuut_${this._styleCounter}`);
            });
        });
        personen.personen.forEach(element => {
            element.objecten.forEach(item => {
                this.maakAttribuutStijl(item.naam, item.afbeeldingpad, `stijl_attribuut_${this._styleCounter}`);
            });
        })
        bewegingen.bewegingen.forEach(element => {
            element.objecten.forEach(object => {
                if(object.static){
                    this.maakBewegingStijl(object.naam, object.afbeeldingpad, `stijl_beweging_${this._styleCounter}`);
                }else{
                    canvasInstantie._graph.getStylesheet().putCellStyle(`stijl_${object.naam}`, object.stijl);
                    this._htmlElementStyles.set(object.naam, object.stijl);
                }
            })
        })
        var stijl = this._styleSheet.getTekstVlakStijl();
        this._htmlElementStyles.set('tekstvlak', 'stijl_tekstvlak');
        canvasInstantie._graph.getStylesheet().putCellStyle('stijl_tekstvlak', stijl);
        
        stijl = this._styleSheet.getLegeActiviteitStijl();
        this._htmlElementStyles.set('lege-activiteit', 'stijl_lege-activiteit')
        canvasInstantie._graph.getStylesheet().putCellStyle('stijl_lege-activiteit', stijl);
    }

   /// <summary>
   ///	Maakt de mx stijl voor een object/attribuut
   /// </summary>
   /// <param name='naam'> Naam van het object</param>
   /// <param name='url'> Url van het object (afbeelding)</param>
   /// <param name='stijlnaam'> Naam voor de mx stijl</param>
    maakAttribuutStijl(naam, url, stijlnaam){
        if (this._htmlElementStyles.has(naam)){
            this._styleCounter++;
            return this._htmlElementStyles[naam];
        }

        // check of er een rotatie in het attribuut zit
        if (stijlnaam.includes(';')) {
                
            stijlnaam = stijlnaam.substring(stijlnaam.indexOf(';'), 0);
        }

        var stijl = this._styleSheet.getAttribuutStijl(url);
        this._htmlElementStyles.set(naam, stijlnaam);
        this._styleCounter++;
        canvasInstantie._graph.getStylesheet().putCellStyle(stijlnaam, stijl);
    }


    /// <summary>
   ///	Maakt de mx stijl voor een beweging
   /// </summary>
   /// <param name='naam'> Naam van het object</param>
   /// <param name='url'> Url van het object (afbeelding)</param>
   /// <param name='stijlnaam'> Naam voor de mx stijl</param>
    maakBewegingStijl(naam, url, stijlnaam){
        if (this._htmlElementStyles.has(naam)){
            this._styleCounter++;
            return this._htmlElementStyles[naam];
        }

        // check of er een rotatie in het attribuut zit
        if (stijlnaam.includes(';')) { 
            stijlnaam = stijlnaam.substring(stijlnaam.indexOf(';'), 0);
        }

        var stijl = this._styleSheet.getBewegingStijl(url);
        this._htmlElementStyles.set(naam, stijlnaam);
        this._styleCounter++;
        canvasInstantie._graph.getStylesheet().putCellStyle(stijlnaam, stijl);
    }
    

    /// <summary>
    ///	Haalt een attribuut op via ID
    /// </summary>
    /// <param name='id'> Id van het op te halen attribuut</param>
    /// <return>
    ///	Het opgehaalde attribuut
    /// </return>
    getAttribuutMetID(id) {
        var returnWaarde = null;
        this._cellen.forEach(element => {
            if (element._id === id){
                returnWaarde = element;
            }
        });
        this._groepen.forEach(element => {
            if (element._id === id){
                returnWaarde = element;
            }
        })
        return returnWaarde;
    }

    /// <summary>
    ///	Haalt de key op van een waarde in de cell id's map
    /// </summary>
    /// <param name='cell'> waarde in de map</param>
    /// <return>
    ///	De key van de waarde in de map
    /// </return>
    getKeyVanWaardeInMap(cell){
        if (cell === undefined)return undefined;
        for (let [k,v] of this._celIDs){
            if (v.id === cell.id){
                return k;
            }
        }
        return undefined;
    }

    /// <summary>
    ///	Check of waarde in de map zit
    /// </summary>
    /// <param name='map'>Map om in te checken</param>
    /// <param name='value'>Waarde om naar te zoeken</param>
    /// <return>
    ///	Boolean of de waarde in de map zit
    /// </return>
    isValueInMap(map, value){
        for (let [k,v] of map){
            if (k){
                if (v === value){
                    return true;
                }
            }
        }
        return false;
    }

    /// <summary>
    ///	 Maakt een array van alle cellen
    /// </summary>
    /// <return>
    ///	Array met alle cellen
    /// </return>
    getAlleMxCells(){
        var cells = [];
        for (let [k,v] of this._celIDs){
            if(k){
                cells.push(v);
            }
        }
        return cells;
    }

    /// <summary>
    ///	Zoekt naar het object naam via de stijlnaam
    /// </summary>
    /// <param name='stijlnaam}'>stijlnaam van het op te zoeken object</param>
    /// <return>
    ///	De naam van het gevonden object
    /// </return>
    getAttribuutNaamFromStijlNaam(stijlnaam){
        if (stijlnaam.includes("rotation")) {
            stijlnaam = stijlnaam.split("rotation=").pop().slice(0, -1);
        }
        var naam = '';
        for(let [k,v] of this._htmlElementStyles){
            if (v == stijlnaam){
                naam = k;
            }
        }
        return naam;
    }

    /// <summary>
    ///	een cell kopiëren => toevoegen aan de map en aan de attributen array
    /// </summary>
    /// <param name='cell'> De nieuwe cell die moet worden opgeslagen</param>
    /// <param name='selectedCell'> De cell waar een kopie van gemaakt is</param>
    kopieerCell(cell, selectedCell){
        var key = this.getKeyVanWaardeInMap(selectedCell);
        var oudAttribuut = this.getAttribuutMetID(key);

        var object;
        var nieuwAttribuut;

        if (oudAttribuut._instantieVan == 'attribuut'){
            object = this.getJsonObject(oudAttribuut._naam).returnItem;
            nieuwAttribuut = new Attribuut(
               this, 
               object, oudAttribuut._stijlnaam);
            cell.geometry.x += 10;
            cell.geometry.y += 10;
            this.saveAttribuut(nieuwAttribuut, cell);
        } 
        // het is een beweging
        else if (oudAttribuut._instantieVan == 'beweging'){
            object = this.getJsonObject(oudAttribuut._naam).returnItem;
            nieuwAttribuut = new Lijn(this, object, oudAttribuut._stijl);

            cell.geometry.sourcePoint.y += 10;
            cell.geometry.sourcePoint.x += 10;
            cell.geometry.targetPoint.y += 10;
            cell.geometry.targetPoint.x += 10;
            if (cell.geometry.points && cell.geometry.points.length >0){
                cell.geometry.points.forEach(point => {
                    point.x += 10;
                    point.y += 10;
                })
                nieuwAttribuut._punten = cell.geometry.points;
            }
            nieuwAttribuut._startPunt = cell.geometry.sourcePoint;
            nieuwAttribuut._eindPunt = cell.geometry.targetPoint;
            this.saveAttribuut(nieuwAttribuut, cell);
        } else if (oudAttribuut._instantieVan == 'groep'){
            nieuwAttribuut = new Groep(oudAttribuut._children, oudAttribuut._stijlnaam, 0);
            cell.geometry.x += 10;
            cell.geometry.y += 10;

            this._groepen.push(nieuwAttribuut);
            this._celIDs.set(nieuwAttribuut._id, cell);
        }
    }

    /// <summary>
    ///	 Verwijderd alle cellen in de array
    /// </summary>
    /// <param name='cells'>Array met cellen die verwijderd moeten worden</param>
    verwijderCell(cells){
        cells.forEach(element => {
            var key = this.getKeyVanWaardeInMap(element)
            if (key != null){
                this._celIDs.delete(key);
                var attribuut = this.getAttribuutMetID(key);
                var index;
                if (attribuut._instantieVan != 'groep'){
                    index = this._cellen.indexOf(attribuut);
                    this._cellen.splice(index,1);
                } else {
                    index = this._groepen.indexOf(attribuut);
                    this._groepen.splice(index,1);
                }
            }
        });
    }

    /// <summary>
    ///	update celID's met alle cellen die op de graph staan want -> het kan zijn dat er na undo's / redo's cellen niet toegevoegd zijn
    /// </summary>
    updateCellIDs(){
        var cells = canvasInstantie._graph.getModel().cells;
        for(var i =0;cells[i]!= undefined;i++){
            if (cells[i].style){
                if (!this.isValueInMap(this._celIDs, cells[i])){
                    var naam = this.getAttribuutNaamFromStijlNaam(cells[i].style);
                    var attribuut;
                    if (this.getJsonObject(naam).categorieName == 'bewegingen'){
                        attribuut = new Lijn(this, this.getJsonObject(naam).returnItem, cells[i].style);
                    } else if (this.getJsonObject(naam).categorieName == 'attributen' || this.getJsonObject(naam).categorieName == 'personen') {
                        attribuut = new Attribuut(this, this.getJsonObject(naam).returnItem, cells[i].style);
                    }
                    this.saveAttribuut(attribuut, cells[i]);
                }
            }
        }
    }

    /// <summary>
    ///	update alle attributen naar de waarden van de mxcellen bij het opslaan van de plattegrond
    /// </summary>
    updateAttributen(){
        this.updateCellIDs();
        for (let [key,v] of this._celIDs){
            var attribuut = this.getAttribuutMetID(key);
            if (attribuut){
                if (attribuut._instantieVan != "groep"){

                    // als de parent een groep is dan moet de x en y goed gezet worden
                    if (v.parent.style) {
                        if (v.parent.style.includes('groep')){
                            if (attribuut._instantieVan == 'beweging'){
                                v.geometry.sourcePoint.x += v.parent.geometry.x;
                                v.geometry.sourcePoint.y += v.parent.geometry.y;
                                v.geometry.targetPoint.x += v.parent.geometry.x;
                                v.geometry.targetPoint.y += v.parent.geometry.y;
                                if (v.geometry.points){
                                    v.geometry.points.forEach(point => {
                                        point.x += v.parent.geometry.x;
                                        point.y += v.parent.geometry.y;
                                    })
                                }
                            } else {
                                v.geometry.x += v.parent.geometry.x;
                                v.geometry.y += v.parent.geometry.y;
                            }
                            attribuut._groepId = this.getKeyVanWaardeInMap(v.parent);
                        }
                    }
                    this.updateAttribuutProperties(attribuut, v);

                    attribuut._stijlnaam = v.style;
                    attribuut._label = v.value;
                    if (v.style.includes("rotation")) {
                        attribuut._rotatie = v.style.split("rotation=").pop().slice(0, -1);
                    }
                }
            }
        }
    }

    /// <summary>
    ///	Update de properties van de attributen aan de hand van de up-to-date mx cell
    /// </summary>
    /// <param name='attribuut'>Het up te daten attribuut</param>
    /// <param name='cell'>De up-to-date mx cell</param>
    updateAttribuutProperties(attribuut, cell){
        if (attribuut._instantieVan == 'attribuut'){

            attribuut._x = cell.geometry.x;
            attribuut._y = cell.geometry.y;
            attribuut._breedte = cell.geometry.width;
            attribuut._hoogte = cell.geometry.height;

        } else if(attribuut._instantieVan == 'beweging') {
            attribuut._hoogte = cell.geometry.height;
            attribuut._breedte = cell.geometry.width;
            attribuut._startPunt = cell.geometry.sourcePoint;
            attribuut._eindPunt = cell.geometry.targetPoint;
            attribuut._punten = cell.geometry.points;
        }
    }

    /// <summary>
    ///	Maak groepen van cellen met een groep ID
    /// </summary>
    makeGroupsFromCells(){
        var groepMap = new Map();
        for (let [k,v] of this._celIDs){
            var attribuut = this.getAttribuutMetID(k);
            if(attribuut._groepId != null){
                if (groepMap.has(attribuut._groepId)){
                    groepMap.get(attribuut._groepId).push(v);
                } else {
                    groepMap.set(attribuut._groepId, [v]);
                }
            }
        }

        for (let [k,v] of groepMap){
            if (k){
                canvasInstantie._graph.setSelectionCells(v);
                canvasInstantie.groeperen();
            }
        }
    }

}
export const werkveldInstantie = new WerkVeld();
