<template>
    <div class="toolbar-item">
      <template v-for="(categorie, index) in ToolList">
        <div v-for="(item, index) in categorie.items" :key="index+categorie.naam" :id="'function-div-'+item.name" class="function-div">
          <div v-on:click="toolbar(item.name)" class="function" :id="item.name" :title=item.name>
            <img v-bind:src="require('../../assets/toolbarIcons/' + item.src)"/>
            <a class="d-none d-md-block">{{ item.name }}</a>
          </div>
        </div>
        <div class="group-functions" v-bind:key="index" v-if="ToolList.length != index+1"></div>
      </template>
    </div>
</template>

<script>
import { canvasInstantie } from "../../business/canvas";
import { backgroundHandlerInstantie } from "../../business/backgroundHandler"

export default {
  name: "App",
  data() {
    return {
    ToolList: [
        {
          naam: "hierarchie",
          items: [
            { src: 'naar_voren.svg', name: 'Naar voren', },
            { src: 'naar_achteren.svg', name: 'Naar achter' },
          ]
        },
        {
          naam: "groeperen",
          items: [
            { src: 'group.svg', name: 'Groeperen' },
            { src: 'ungroup.svg', name: 'Degroeperen' },
          ]
        },
        {
          naam: "undoredo",
          items: [
            { src: 'undo.svg', name: 'Ongedaan maken' },
            { src: 'redo.svg', name: 'Opnieuw doen' },
          ]
        },
        {
          naam: "overig",
          items: [
            { src: 'maak_kopie.svg', name: 'Maak kopie' },
            { src: 'grid.svg', name: 'Raster' },
            { src: 'Tekstvlak.svg', name: 'Tekstvlak' },
            { src: 'verwijderen.svg', name: 'Verwijderen' },
          ]
        }
      ],
    };
  },
  methods: {
    async toolbar(tool) {
      switch (tool) {
        case "Naar voren":
          canvasInstantie.hierarchieNaarVoren();
          break;
        case "Naar achter":
          canvasInstantie.hierarchieNaarAchter();
          break;
        case "Maak kopie":
          canvasInstantie.cloneCells();
          break;
        case "Groeperen":
          canvasInstantie.groeperen();
          break;
        case "Degroeperen":
          canvasInstantie.degroeperen();
          break;
        case "Ongedaan maken":
          canvasInstantie.onUndo();
          break;
        case "Opnieuw doen":
          canvasInstantie.onRedo();
          break;
        case "Verwijderen":
          canvasInstantie.verwijderCell();
          break;
        case "Raster":
          this.schakelActiveClass(tool);
          await this.schakelGrid()
          break;
        case "Tekstvlak":
          canvasInstantie.plaatsAttribuutBijKlik('tekstvlak', 'tekst');
          break;
      }
    },
    schakelActiveClass(element) {
      let btn = document.getElementById('function-div-'+element);
      btn.classList.toggle("function-div-active");
    },
    async schakelGrid() {
      if (backgroundHandlerInstantie._gridEnabled) {
        backgroundHandlerInstantie._gridEnabled = false;
        backgroundHandlerInstantie._grid = "";
      } else {
        backgroundHandlerInstantie._gridEnabled = true;
        await backgroundHandlerInstantie.setGrid();
      }
      let dataUrl = await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte)
      this.veranderAchtergrondFoto(dataUrl);
    },
    veranderAchtergrondFoto(dataUrl) {
      canvasInstantie.veranderCellAchtergrondSvg(dataUrl);
    },
  }
};
</script>

<style scoped>

a{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toolbar-item{
  display: flex;
  margin: auto;
  height: 90%;
  width: 100%;
}

.group-functions{
    margin-left: .3rem;
    margin-right: .3rem;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.295);
}

.function-div:hover {
  background-color: rgb(21, 138, 202);
}

.function-div-active{
  background-color: rgb(21, 138, 202);
}

.function{
    margin-left: .3rem;
    margin-right: .3rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-around;
    cursor: pointer;
}


.function img{
    margin-left: .7rem;
    margin-right: .7rem;
    padding-bottom: 4px;
    width: 1.4rem;
    height: 1.4rem;
    align-self: center;
}

.function a{
    font-size: 0.65rem;
    width: 100%;
    font-weight: lighter;
    color: rgb(255, 255, 255) !important;
}

.activiteit-button {
  max-height: 2rem;
}
</style>