import { Cel } from "./cel";

export class Knoop extends Cel {
    _x;
    _y;
    _z;
    _breedte;
    _hoogte;
    _rotatie;

    constructor(){
        super();
        this._x = 0;
        this._y = 0;
        this._z = 0;
        this._rotatie = 0;
        this._breedte = 0;
        this._hoogte = 0;
    }

    // Getters en setters
    getX(){
        return this._x;
    }
    setX(value){
        this._x = value;
    }
    getY(){
        return this._y;
    }
    setY(value){
        this._y = value;
    }
    getBreedte(){
        return this._breedte;
    }
    setBreedte(value){
        this._breedte = value;
    }
    getHoogte(){
        return this._hoogte;
    }
    setHoogte(value){
        this._hoogte = value;
    }
}