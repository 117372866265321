<template>
<div class="container stapinhoud-container">

    <div class="search-section">
        <input class="zoek-input" type="text" v-model="search" placeholder="Zoek materialen" id="zoekInput">
        <font-awesome-icon class="search-icon" icon="search"/>
    </div>

    <span class="span-header unselectable">Sleep materialen in het veld</span>

    <!-- Als de search leeg is, laat alle attributen per categorie zien. -->
    <!-- <div class="dropdown-container" v-if="search === ''"> -->
    <div class="dropdown-container" id="dropdown-container">
        <div class="accordion" v-for="(item) in categorieAttributen" v-bind:key="item.naam" v-bind:id="'cat-'+item.id">
            <div v-on:click="schakelDropdown(item.id)" class="accordion-row" v-bind:title="item.naam">
                <a  class="accordion-button unselectable textOverflow" >{{item.naam}}</a>
                <div class="accordion-icons">
                    <font-awesome-icon class="down-icon" v-bind:id="'chevron-down-icon-'+item.id" icon="chevron-down" />
                    <font-awesome-icon v-bind:id="'chevron-up-icon-'+item.id" icon="chevron-up" class="up-icon hidden" />
                </div>
            </div>
            <div class="accordion-content" v-bind:id="'cat-content-'+item.id">
                <!-- Subcategorieen -->
                <div class="subcats">
                    <div v-for="subcategory in item.subcategorieen" v-bind:key="subcategory.naam" v-bind:id="'subcat-'+subcategory.id">
                        <div class="accordion-row" v-on:click="schakelSubcategorie(subcategory.id)" v-bind:title="subcategory.naam">
                            <a  class="accordion-button-subcat unselectable textOverflow">{{subcategory.naam}}</a>
                            <div class="accordion-icons">
                                <font-awesome-icon class="down-icon" v-bind:id="'subcat-chevron-down-icon-'+subcategory.id" icon="chevron-down" />
                                <font-awesome-icon v-bind:id="'subcat-chevron-up-icon-'+subcategory.id" icon="chevron-up" class="up-icon hidden" />
                            </div>
                        </div>
                        <!-- Objecten van subcategorieen -->
                        <div v-bind:id="'sub-content-'+subcategory.id" class="accordion-content">
                            <div class="accordion-div attribuutIcoon" v-for="object in subcategory.objecten" v-bind:key="object.naam">
                                <sleepbaar-lijst-component :item="object" :zoek="'subcat-attribuut-'"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Losse objecten in categorie -->
                <div class="accordion-div attribuutIcoon" v-for="object in item.objecten" v-bind:key="object.naam">
                  <sleepbaar-lijst-component :item="object" :zoek="'attribuut-'"/>
                </div>
            </div>
        </div>
    </div>

    <!-- Als de search niet leeg is, laat alle attributen zien die voldoen aan de search -->
    <div class="search-container">
        <ul class="search-list hidden" id="search-list">
            <div class="accordion-div attribuutIcoon" v-for="item in gefilterdeLijst" v-bind:key="item.naam">
                <sleepbaar-lijst-component :item="item" :zoek="'zoek-attribuut-'"/>
            </div>
        </ul>
    </div>
</div>
</template>
<script>
import json from '../../../assets/objecten.json';
import DropdownHandler from '../../../business/dropdownHandler'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SleepbaarLijstComponent from './SleepbaarLijstComponent.vue';

library.add([faChevronUp, faChevronDown, faSearch]);

export default {
    data() {
        return {
            search: '',
            categorieAttributen: json.groepen,
            attributen: this.setAttributen(),
            gefilterdeLijst: [],
            icon: '',
            dropdownHandler: null,
        }
    },
    components: {
        FontAwesomeIcon,
        SleepbaarLijstComponent
    },
    watch: {
        search: function() {
            this.gefilterdeLijst = [];
            // update de gefilterde lijst
            this.gefilterdeLijst = this.attributen.filter(i => { return i.naam.toLowerCase().includes(this.search.toLowerCase())});
            var dropdown = document.getElementById("dropdown-container");
            var searchlist = document.getElementById("search-list");
            if (this.search != '' && searchlist.classList.contains('hidden')) {
                searchlist.classList.remove('hidden');
                searchlist.classList.add('active');
                dropdown.classList.add("hidden");
            } else if (this.search == '' && dropdown.classList.contains('hidden')){
                dropdown.classList.remove('hidden');
                searchlist.classList.add('hidden');
            }
        }
    },
    mounted: function () {
        this.dropdownHandler = new DropdownHandler()
    },
    methods: {
        // Return alle attributen uit alle categorieën
        setAttributen: function () {
            var attributenArray = [];
            for (var i=0;i<json.groepen.length;i++){
                json.groepen[i].objecten.forEach(element => {
                    attributenArray.push(element);
                });
            }
            return attributenArray;
        },
        schakelDropdown(id) {
            this.dropdownHandler.schakelDropdown(id)
        },
        schakelSubcategorie(id) {
            this.dropdownHandler.schakelSubcategorie(id)
        },
        getIcon(url){
            return url && `/img/icons/${url}`
        }
    }
}
</script>
<style scoped>
@import '/css/stapinhoud.css';
@import '/css/dropdown.css';


.tekstvlak {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.accordion-content {
    display: none;
    color: black;
    font-size: 0.8rem;
    background: white;
}

.accordion-button-subcat{
    padding: 2px !important;
    display: inline-flex;
    align-items: center;
    padding-left: 1rem !important;
}



.dropdown-container {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    max-height: 100%;
    height: 100%;
}

/* width */
.dropdown-container::-webkit-scrollbar {
  width: 5px;
  position: absolute;
  right: 10px;
}

/* Track */
.dropdown-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.dropdown-container::-webkit-scrollbar-thumb {
  background: rgb(1, 128, 198); 
  border-radius: 10px;
}

/* Handle on hover */
.dropdown-container::-webkit-scrollbar-thumb:hover {
  background: rgb(2, 86, 131); 
}


/* voor de zoeklijst*/
/* width */
.search-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.search-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.search-list::-webkit-scrollbar-thumb {
  background: rgb(1, 128, 198); 
  border-radius: 10px;
}

/* Handle on hover */
.search-list::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 65, 100); 
}

.search-section {
    position: relative;
    display: flex;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 0.1rem solid grey;
    color: grey;
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    background: white;
    cursor: pointer;
    justify-content: space-between;
    background-color: white;
}


.search-icon {
    display: flex;
    position: absolute;
    right: .4rem;
    height: 100%;
}

.zoek-input {
    display: flex;
    text-align: left;
    width: 100%;
    font-size: 0.9rem;
    padding: 0.3rem;
}

.search-list {
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    color: black;
    font-size: 0.8rem;
    background: white;
    padding: 0;
}
.list-content {
    display: block;
    color: black;
    font-size: 0.8rem;
    width: 100%;
}
.show {
    display: block;
    background: white;
}

.subcat-show {
    display: block;
    background: rgb(242, 248, 252);
}

.active {
    border: 1px solid rgb(1, 128, 198);
    color: rgb(1, 128, 198);
    padding-top: 0.5rem;
    box-shadow: 0 0 5px #aaaaaa;
}

.subcat-active{
    color: rgb(1, 128, 198);
}

.accordion-div {
    display: flex;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
}

.subcats {
    margin-bottom: 0.3rem;
}

.subcat-accordion {
    border: 1px solid rgb(1, 128, 198);
    margin: 0.2rem;
    padding: 0.2rem;
}

</style>