<template>
  <div class="container  stappenlijstContainer">
    <ul class="stappenlijst">
      <template v-for="(item, index) in listItems">
      <li v-bind:id="item.name" class="stapKnop" v-on:click="updateStapInhoud(index)" :key="item.id+item.name">
        <div><a class="stap-nummer">{{ index + 1 }}</a><a class="stap-naam">{{ item.name }}</a></div>
      </li>
      </template>
    </ul>
    <button class="opslaan-btn" @click="bewaarPlattegrond()">
      Bewaar plattegrond
    </button>
  </div>
</template>
<script>
import { canvasInstantie } from "../../business/canvas";
import { xmlHandlerInstantie } from '../../business/xmlHandler';
export default {
  data() {
    return {
      listItems: this.getLesOfActiviteit(),
    };
  },

  mounted(){
    this.updateStapInhoud(0);
  },

  methods: {
    getLesOfActiviteit(){
      if (document.getElementById("isLesIndeling")){
        return [
          { name: 'Achtergrond' },
          { name: 'Activiteiten'},
          { name: 'Materialen' },
          { name: 'Personen' },
          { name: 'Lijnen en pijlen' },
        ];
      }
      return [
        { name: 'Achtergrond' },
        { name: 'Materialen' },
        { name: 'Personen' },
        { name: 'Lijnen en pijlen' },
      ];
    },
    updateStapInhoud(value) {
      this.listItems.forEach(item => {
        document.getElementById(item.name).getElementsByTagName("div")[0].classList.remove("active");
      });
      
      document.getElementById(this.listItems[value].name).getElementsByTagName("div")[0].classList.add("active");


      this.$emit("update:nieuweStap", value+1);
    },
    veranderAchtergrondFoto(dataUrl) {
      canvasInstantie.veranderCellAchtergrondSvg(dataUrl);
    },
    bewaarPlattegrond() {
      xmlHandlerInstantie.bewaarGrafiek();
    }
  },
};
</script>

<style scoped>


.stappenlijstContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 15vh;
}

.stappenlijst {
  padding-left: 0;
  padding-right: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  width: 100%;
  padding-bottom: 5vh;
  border-top: rgb(221, 221, 221) solid 1px;
}

li div a {
  display: flex;
  font-size: 13px;
  vertical-align: baseline;
}

.stap-nummer {
  background-color: #0180c6;
  border-radius: 50%;
  padding: 0.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
  color: white !important;
}

.stap-naam{
  padding-left: 1rem;
  text-align: left;
}

li div {
  border-bottom: rgb(221, 221, 221) solid 1px;
  display: flex;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
}

li div.active {
  background-color: #0180c6;
  color: white;
}

li div.active:hover {
  color: white;
}

li div.active a.stap-nummer {
  background-color: white;
  color: #0180c6 !important;
}

li div:not(.active):hover {
  background-color: #555;
  color: white;
}

.opslaan-btn {
  background-color: #f78f1f;
  width: 80%;
  align-self: center;
  color: white;
  padding: 12px 0px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
