import { canvasInstantie } from "./canvas";

export default class BackgroundHandler {
    _achtergrond = "";
    _lijnenset = "";
    _indeling = "";
    _grid = "";
    _dataUrl = "";
    _backgroundKleur = "";
    _contrastBackground = ["#4C7AB0","#7F7F7F","#C8BFE7","#FFAEC9","#FFC90E","#B5E61D","#99D9EA","#D1A570"];
    _gridEnabled = false;

    constructor() {

    }

    /// <summary>
    ///	fetchSvgAsXml haalt svg file op en returned deze als xml document
    /// </summary>
    /// <param name='src'>Path naar SVG</param>
    /// <return>
    ///	XML document
    /// </return>
    fetchSvgAsXml(src) {
        return new Promise(function (resolve, reject) {
            var xmlDoc = null;
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    xmlDoc = this.responseXML;
                    if (xmlDoc != null) {
                        resolve(xmlDoc);
                    }
                    else {
                        reject('doc is null');
                    }
                }
            };
            xhttp.open("GET", src, true);
            xhttp.send();
        });
    }

    /// <summary>
    ///	convertAfbeeldingToDataURL haalt een afbeelding op en maakt hier een dataURL van
    /// </summary>
    /// <param name='src'>Path naar PNG</param>
    /// <return>
    ///	Afbeelding als dataURL
    /// </return>
    async convertAfbeeldingToDataURL(src) {
        let blob = await fetch(src).then(response => response.blob());
        let dataUrl = await new Promise(resolve => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
        return dataUrl
    }

    /// <summary>
    ///	getXmlTagContentAsString returned de xml inhoud van een de meegegeven xml tag
    /// </summary>
    /// <param name='xmlDoc'>XML document waaruit wordt gewerkt</param>
    /// <param name='tag'>XML tag waarvan de content wordt gereturned (exclusief de meegegeven tag)</param>
    /// <return>
    /// XML content als string
    /// </return>
    getXmlTagContentAsString(xmlDoc, tag) {
        let xmlText = new XMLSerializer().serializeToString(xmlDoc);
        xmlText = xmlText.substring(xmlText.indexOf(">") + 1);
        xmlText = xmlText.replace(tag, "");
        return xmlText;
    }

    /// <summary>
    ///	createCombinedBackground voegt een achtergrond, lijnenset, indeling en grid samen tot 1 SVG
    /// Vervolgens wordt hier base64 encoded een dataURL van gemaakt
    /// </summary>
    /// <param name='background'>XML content voor de achtergrondlaag</param>
    /// <param name='lijnenset'>XML content voor de lijnensetlaag</param>
    /// <param name='indeling'XML content voor de indelingenlaag></param>
    /// <param name='grid'>XML content voor de rasterlaag</param>
    /// <param name='width'>Breedte van de afbeelding</param>
    /// <param name='height'>Hoogte van de afbeelding</param>
    /// <return>
    ///	Afbeelding als dataURL
    /// </return>
    async createCombinedBackground(background, lijnenset, indeling, grid, width, height) {
        // Haalt afbeelding op voor de achtergrondlaag en plaatst deze in een <image> tag
        let backgroundContent = "";
        if (background.includes("<image") || background.includes("<rect")) {
            backgroundContent = background;
        } else if (background != "") await backgroundHandlerInstantie.convertAfbeeldingToDataURL(background).then(function(backgroundPng) {
            backgroundContent = `<image href="${backgroundPng}" preserveAspectRatio="none" height="100%" width="100%"/>`;
        })
        // Haalt afbeelding op voor de lijnensetlaag en plaatst deze in een <image> tag
        let lijnensetContent = "";
        if (lijnenset.includes("<image")) {
            lijnensetContent = lijnenset;
        } else if (lijnenset != "") await backgroundHandlerInstantie.convertAfbeeldingToDataURL(lijnenset).then(function(lijnensetPng) {
            lijnensetContent = `<image href="${lijnensetPng}" preserveAspectRatio="none" height="100%" width="100%"/>`;
        })

        // Losse elementen worden samengevoegd tot 1 XML string
        let xmlString = `<svg fill="none" width="100%" height="100%" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg"><g width="100%" height="100%" id='background'>${backgroundContent}</g><g width="100%" height="100%" id='lijnenset'>${lijnensetContent}</g><g width="100%" height="100%" id='indeling'>${indeling}</g><g width="100%" height="100%" id='grid'>${grid}</g></svg>`
        // String wordt encoded naar base64
        let encodedString = window.btoa(xmlString);
        // String wordt omgezet naar een dataURL
        let dataUrl = `data:image/svg+xml;base64,${encodedString}`
        this._dataUrl = dataUrl;
        return dataUrl
    }

    /// <summary>
    ///	setCombinedBackgroundAttributesFromDataURL decodeerd een dataURL en haalt hier de verschillende background attributen uit
    /// Vervolgens worden deze geset in de variables this._achtergrond, this._lijnenset, this._indeling en this._grid. 
    /// </summary>
    /// <param name='dataUrl'>DataURL om te decoderen</param>
    async setCombinedBackgroundAttributesFromDataURL(dataUrl) {
        let encodedString = dataUrl.substring(dataUrl.indexOf(",") + 1);

        let svg = window.atob(encodedString);
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(svg, "text/xml");

        let background = xmlDoc.getElementById("background");
        this._achtergrond = this.getXmlTagContentAsString(background, "</g>");

        let lijnenset = xmlDoc.getElementById("lijnenset");
        this._lijnenset = this.getXmlTagContentAsString(lijnenset, "</g>");

        let indeling = xmlDoc.getElementById("indeling");
        this._indeling = this.getXmlTagContentAsString(indeling, "</g>");

        let grid = xmlDoc.getElementById("grid");
        this._grid = this.getXmlTagContentAsString(grid, "</g>");
    }

    /// <summary>
    ///	getCombinedBackgroundWithoutGrid returned de gecombineerde achtergrond zonder grid, in de XML tag <achtergrond>
    /// </summary>
    /// <return>
    ///	XML tag <achtergrond> met daarin de dataUrl van de gecombineerde achtergrond
    /// </return>
    async getCombinedBackgroundXml() {
        var data = await this.createCombinedBackground(this._achtergrond, this._lijnenset, this._indeling, '', canvasInstantie._bladBreedte, canvasInstantie._bladHoogte);
        return `<achtergrond dataUrl="${data}"></achtergrond>`;
    }

    /// <summary>
    ///	createColorBackgroundSvg maakt een SVG vierkant met 1 solide kleur
    /// </summary>
    /// <param name='kleur'>Kleur die het vierkant moet krijgen</param>
    /// <return>
    ///	SVG string
    /// </return>
    createColorBackgroundSvg(kleur) {
        return `<rect width="100%" height="100%" fill="${kleur}"/>`
    }

    /// <summary>
    ///	setGrid zet het raster in de juiste kleur, aan de hand van de huidige achtergrondkleur
    /// </summary>
    async setGrid() {
        let xmlFile;
          if (this._contrastBackground.includes(this._backgroundKleur)) {
            xmlFile = await this.fetchSvgAsXml(`/img/grid-white.svg`);
          } else {
            xmlFile = await this.fetchSvgAsXml(`/img/grid.svg`);
          }
          this._grid = this.getXmlTagContentAsString(xmlFile, "</svg>");
      }
}

export const backgroundHandlerInstantie = new BackgroundHandler();
