import { canvasInstantie } from "./canvas";
import { werkveldInstantie } from "./werkveld";
import {backgroundHandlerInstantie} from "./backgroundHandler";
import { Attribuut } from "./cellen/attribuut";
import { Lijn } from "./cellen/lijn";
export default class XmlHandler {
        /// <summary>
    ///	Sla de grafiek op door een xml string te maken
    /// </summary>
    async bewaarGrafiek() {
        werkveldInstantie.updateAttributen();
        var xmlString = '<zaalindeling versie="2.0">';

        //voeg achtergrond toe als die er is
        if (backgroundHandlerInstantie._xmlString != ""){
            xmlString += await backgroundHandlerInstantie.getCombinedBackgroundXml();
        }

        werkveldInstantie._cellen.forEach(element => {
            xmlString += element.getXml();
        });
        xmlString += '</zaalindeling>'
        console.log(xmlString);
    }

    /// <summary>
    ///	Wanneer er xml in de html pagina staat die ingelezen moet worden, lees deze xml in
    /// </summary>
    async openMetActiviteitXml(){
        var xml = document.getElementById("activiteit-xml");
        if (xml == null) return
        xml = xml.innerHTML;
        if (xml == null || xml.trim() == "") return
        xml = xml.replaceAll("&lt;", '<');
        xml = xml.replaceAll("&gt;", ">");
        canvasInstantie.placeMxCells(await this.createAttributenVanXml(xml), false);
        werkveldInstantie.makeGroupsFromCells();
        canvasInstantie.correctCellsToCanvas();
    }

    /// <summary>
    ///	Laad een activiteit in wanneer er op een activiteit geklikt wordt.
    /// </summary>
    /// <param name='xml'> XML string waarin de activiteit staat opgeslagen</param>
    async laadActiviteitInVoorLes(xml){
        canvasInstantie.placeMxCells(await this.createAttributenVanXml(xml), true);
        canvasInstantie.correctCellsToCanvas();
    }

    /// <summary>
    ///	plaats een tekstvlak met achtergrond voor een activiteit met een lege xml
    /// </summary>
    /// <param name='naam'>Naam van tekstvlak</param>
    /// <param name='tekst'> Tekst die in het tekstvlak moet komen</param>
    plaatsActiviteitMetLegeXml(naam, tekst){
        var graph = canvasInstantie._graph;
        var parent =graph.getDefaultParent();
        var vertex = null;
        var x = parseFloat(canvasInstantie._bladBreedte / 2) + 0.00000001;
        var y = parseFloat(canvasInstantie._bladHoogte / 2) + 0.00000001;
        var attribuut = new Attribuut(werkveldInstantie, werkveldInstantie.getJsonObject(naam).returnItem, werkveldInstantie._htmlElementStyles.get(naam));
        graph.getModel().beginUpdate();
        try
        {
            vertex =graph.insertVertex(parent, null, tekst, x - (attribuut.getBreedte() * canvasInstantie._relativeX /2), y - (attribuut.getHoogte() * canvasInstantie._relativeY /2), attribuut.getBreedte() * canvasInstantie._relativeX, attribuut.getHoogte() * canvasInstantie._relativeY, attribuut._stijlnaam);
            werkveldInstantie.saveAttribuut(attribuut, vertex);
        }
        finally
        {
            graph.getModel().endUpdate();
        }
    }

    /// <summary>
    ///	Leest de xml string in en loopt door de xml heen
    /// </summary>
    /// <param name='str'> De door te lopen xml string</param>
    async createAttributenVanXml(str){
        var parser  = new DOMParser();
        var xmlDoc = parser.parseFromString(str, "text/xml");
        var versie = xmlDoc.getElementsByTagName("zaalindeling")[0].getAttribute("versie");
        // check voor een achtergrond node
        var achtergrondNode = xmlDoc.getElementsByTagName("achtergrond");
        if (achtergrondNode.length > 0 ){
            let dataUrl = achtergrondNode[0].getAttribute("dataUrl");
            backgroundHandlerInstantie.setCombinedBackgroundAttributesFromDataURL(dataUrl);
            canvasInstantie.veranderCellAchtergrondSvg(await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte));
        }

        // ga alle objecten langs en maak er attributen van
        var attributen = [];
        xmlDoc.getElementsByTagName("object").forEach(node => {
            var naam = node.children[0].innerHTML;
            // maak een attribuut of lijn van de xml node
            var attribuut;

            if (werkveldInstantie.getJsonObject(naam).categorieName == 'bewegingen'){
                attribuut = this.createBewegingFromXmlNode(naam, node);
            } else {
                attribuut = this.createAttribuutFromXmlNode(naam, node);
            }

            // het is een nieuwe variant van de xml (met eventueel een aangepaste breedte en grootte)
            if (versie == '2.0'){
                attribuut._breedte = node.children[2].getAttribute("breedte") * canvasInstantie._relativeX;
                attribuut._hoogte = node.children[2].getAttribute("hoogte") * canvasInstantie._relativeY;
            } else {
                // het is een oude variant van de xml dus de x en y moeten goed geplaatst worden
                this.correctRotatieOudXml(attribuut);
                this.correctXenYoudXml(attribuut);
            }

            attributen.push(attribuut);
        })
        return attributen;
    }

    /// <summary>
    ///	Maakt een beweging instantie van de xml node
    /// </summary>
    /// <param name='naam'>naam van het json object / de beweging</param>
    /// <param name='node'>De xml node </param>
    createBewegingFromXmlNode(naam, node){
        var lijn;
        lijn = new Lijn(werkveldInstantie, werkveldInstantie.getJsonObject(naam).returnItem, naam);
        lijn._naam = naam;
        lijn._startPunt = JSON.parse(node.children[1].getAttribute("startPunt"));
        lijn._eindPunt = JSON.parse(node.children[1].getAttribute("eindPunt"));
        lijn._punten = JSON.parse(node.children[1].getAttribute("tussenPunten"));
        lijn._label = node.children[3].innerHTML;

        if (node.children[4]){
            lijn._groepId = node.children[4].getAttribute("id");
        }
        return lijn;
    }

    /// <summary>
    ///	Maakt een attribuut instantie van de xml node
    /// </summary>
    /// <param name='naam'>naam van het json object / het attribuut</param>
    /// <param name='node'>De xml node </param>
    createAttribuutFromXmlNode(naam, node){

        // exceptie, verander ' naar ij
        if (naam == 'bank op z\'n zij'){
            naam = naam.replace('\'', 'ij');
        }
        var attribuut;
        // controleer of het een tekstvlak is, zo ja zet label en laad anders in
        if (naam == "tekstvlak" || naam == "lege-activiteit"){
            attribuut = new Attribuut(werkveldInstantie, {naam: naam, afbeeldingpad: null, grootte: [node.children[2].getAttribute("breedte") * canvasInstantie._relativeX,node.children[2].getAttribute("hoogte") * canvasInstantie._relativeY]}, undefined);
            attribuut._label = node.children[4].innerHTML;
            attribuut._stijlnaam = `stijl_${naam}`;
            // als de naam notitie is is het een oude variant dus anders inladen
        } else if (naam == 'notitie'){
            attribuut = new Attribuut(werkveldInstantie, {naam: naam, afbeeldingpad: null, grootte: [100.0,50.0]}, undefined);
            attribuut._label = node.children[2].innerHTML;
            attribuut._stijlnaam = "stijl_tekstvlak";
        } else {
            attribuut = new Attribuut(werkveldInstantie, werkveldInstantie.getJsonObject(naam).returnItem, undefined);
            attribuut.setStijlnaam(werkveldInstantie._htmlElementStyles.get(attribuut._naam));
        }
        attribuut._x = node.children[1].getAttribute("x") * canvasInstantie._relativeX;
        attribuut._y = node.children[1].getAttribute("y") * canvasInstantie._relativeY;
        attribuut._z = node.children[1].getAttribute("z");
        attribuut._rotatie = node.children[1].getAttribute("rotatie");

        if (node.children[5]){
            attribuut._groepId = node.children[5].getAttribute("id");
        }

        return attribuut;
    }

    /// <summary>
    ///	Zet de rotatie van het attribuut goed wanneer het een oud xml bestand is
    /// </summary>
    /// <param name='attribuut'> Het attribuut waarvan de rotatie goed gezet moet worden</param>
    correctRotatieOudXml(attribuut){
        if (attribuut._rotatie != 0){
            if (attribuut._rotatie > 0){
                attribuut._rotatie = Math.abs(attribuut._rotatie) + 180;
            }
        }
    }

    /// <summary>
    ///	Zet de X en Y van het attribuut goed, (bij oude variant is het midden van de graph 0,0 - bij de nieuwe graph is linksbovenin 0,0)
    /// </summary>
    /// <param name='attribuut'> Het attribuut waarvan de x en y goed gezet moeten worden</param>
    correctXenYoudXml(attribuut){
        // parse x
            var xMidden = canvasInstantie._bladBreedte /2;
            var x = parseInt(xMidden) + parseInt(attribuut._x);
            attribuut._x = parseFloat(x) + 0.0000001;

        // parse y
            var yMidden = canvasInstantie._bladBreedte /2;
            var y = parseInt(yMidden) + parseInt(attribuut._y);
            attribuut._y = parseFloat(y) + 0.0000001;
        return;
    }
}
export const xmlHandlerInstantie = new XmlHandler();