import { Knoop } from "./knoop";
import { canvasInstantie} from "./../canvas";

export class Attribuut extends Knoop {
    _naam;
    _afbeelding;
    _dragPlaceHolder;
    _stijlnaam;

    constructor(werkveld, attribuutObject, stijlNaam) {
        super();

        this._werkblad = werkveld;
        this._naam = attribuutObject.naam;
        this._afbeelding = attribuutObject.afbeeldingpad;
        this._instantieVan = 'attribuut';

        if (stijlNaam){
            this._stijlnaam = stijlNaam;
        }
        if (attribuutObject.grootte){
            this.setBreedte(attribuutObject.grootte[0]);
            this.setHoogte(attribuutObject.grootte[1])
        } else {
            this.setBreedte(20);
            this.setHoogte(20);
        }
    }
    
    // Getters en Setters
    getNaam(){
        return this._naam;
    }
    setNaam(value){
        this._naam = value;
    }

    getAfbeelding(){
        return this._afbeelding;
    }
    setAfbeelding(value){
        this._afbeelding = value;
    }

    getStijlnaam(){
        return this._stijlnaam + `;rotation=${this._rotatie};`;
    }

    setStijlnaam(naam){
        this._stijlnaam = naam;
    }

    /// <summary>
    ///	getXml creërt een string met alle waarden die nodig zijn voor de xml van het attribuut object
    /// </summary>
    /// <return>
    ///	Een string met een xml formaat waarin alle waarden van attribuut object staan.
    /// </return> 
    getXml(){
        var totStijlnaam = this._stijlnaam;
        var groepXml = '';
        if (this._groepId != null && this._groepId != 0){
            groepXml = `<groep id="${this._groepId}"></groep>`
        }
        return `<object> ` + 
        `<naam>${this._naam}</naam>` + 
        `<positie x="${this._x / canvasInstantie._relativeX}" y="${this._y / canvasInstantie._relativeY}" z="${this._z}" rotatie="${this._rotatie}"></positie>` +
        `<omvang breedte="${this._breedte / canvasInstantie._relativeX}" hoogte="${this._hoogte / canvasInstantie._relativeY}"></omvang>` +
        `<stijlnaam>${totStijlnaam}</stijlnaam>` +
        `<label>${this._label}</label>` +
        groepXml +
        `</object>`;
    }
}