import { Cel } from "./cel";

export class Lijn extends Cel {
    _stijl;
    _naam;
    _grootte;
    _afbeelding;
    _startPunt;
    _eindPunt;
    _punten;
    _static;
    _breedte;
    _hoogte;

    constructor(werkveld, bewegingObject, stijl){
        super();
        this._werkblad = werkveld;
        this._stijl = stijl;

        this._naam = bewegingObject.naam;
        this._grootte = bewegingObject.grootte;
        this._static = bewegingObject.static;
        this._afbeelding = bewegingObject.afbeeldingpad;

        this._instantieVan = 'beweging';

    }

    // getters en setters
    getBeginPunt(){
        return this._beginPunt;
    }
    setBeginPunt(value){
        this._beginPunt = value;
    }
    getEindPunt(){
        return this._eindPunt;
    }
    setEindPunt(value){
        this._eindPunt = value;
    }

    getGeometry(){
        return {sourcePoint: this._startPunt, targetPoint: this._eindPunt, points: this._punten, width: this._breedte, height: this._hoogte};
    }

    setGeometry(object){
        this._startPunt = object.sourcePoint;
        this._eindPunt = object.targetPoint;
        this._punten = object.points;
        this._breedte = object.width;
        this._hoogte = object.height;
    }

    /// <summary>
    ///	getXml creërt een string met alle waarden die nodig zijn voor de xml van het beweging object
    /// </summary>
    /// <return>
    ///	Een string met een xml formaat waarin alle waarden van beweging object staan.
    /// </return> 
    getXml(){
        var groepXml = '';
        if (this._groepId != null && this._groepId != 0){
            groepXml = `<groep id="${this._groepId}"></groep>`
        }
        return `<object> ` + 
        `<naam>${this._naam}</naam>` + 
        `<positie startPunt='${JSON.stringify(this._startPunt)}' eindPunt='${JSON.stringify(this._eindPunt)}' tussenPunten='${JSON.stringify(this._punten)}' z="${this._z}"></positie>` +
        `<omvang hoogte="${this._hoogte}" breedte="${this._breedte}"></omvang>` +
        `<label>${this._label}</label>` +
        groepXml + 
        `</object>`;
    }
}