import { Knoop } from "./knoop";

export class Groep extends Knoop {
    _children = [];
    _stijlnaam;
    _z;

    /// <summary>
    ///	
    /// </summary>
    /// <param name='children'> De kinderen van de groep</param>
    /// <param name='stijlnaam'> De stijlnaam van de groep</param>
    /// <param name='z'> De z waarde van de groep</param>

    constructor(children, stijlnaam, z){
        super();
        
        this._children = children;
        this._stijlnaam = stijlnaam;
        this._z = z;
        this._instantieVan = "groep";
    }
}