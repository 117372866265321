<template>
  <div id="app" class="app-container">
  <v-app id="inspire">
    <v-row justify="center" class="app-row">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open plattegrond tool
          </v-btn>
        </template>
        <v-card class="card">
          <v-toolbar class="header col-12" color="#0180c6">
            <!-- <img src="img/bsr-logo.png" alt="BSR logo"> -->
            <v-toolbar-title class="toolbar-title col-4 d-none d-md-block">Bewegen Samen Regelen</v-toolbar-title>
            
            <div class="toolbar col-7">
              <Toolbar/>
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn color=white icon dark @click="openPopUp">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <div id="myModal" class="modal">
            <!-- Modal content -->
            <div class="modal-content">
              <span class="close" @click="closePopup()">&times;</span>
              <p class="popup-title">Plattegrond opslaan?</p>
              <div class="popup-btns">
                <button type="button" @click="closeModal(false)" class="deletebtn unselectable">Niet opslaan</button>
                <button type="button" @click="closeModal(true)" class="safebtn unselectable">Opslaan</button>
              </div>
            </div>
          </div>

          <plattegrond-tool class="tool"></plattegrond-tool>

        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</div>
</template>

<script>
import PlattegrondTool from './components/PlattegrondTool.vue';
import Toolbar from "./components/Werkblad/Toolbar.vue";
import {xmlHandlerInstantie} from "./business/xmlHandler";

  export default {
    components: {
      PlattegrondTool,
      Toolbar
    },
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
      }
    },
    methods: {
      openPopUp(){
        var modal = document.getElementById("myModal");
        modal.style.display = "block";
      },
      closeModal(opslaan){
        if(opslaan){
          // data moet worden opgeslagen
          xmlHandlerInstantie.bewaarGrafiek();
          this.dialog = false;
        }else{
          // data wordt niet opgeslagen
          this.dialog = false;
        }
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
      },
      closePopup(){
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
      }
      
    }
  }
</script>

<style scoped>
.app-container{
  margin: 0 !important;
}

.unselectable{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.app-row{
  margin: 0 !important;
  margin-top: 64px !important;
}

.card{
  display: flex;
  flex-direction: column;
}

.header{
  flex-grow: 0;
  background-color: rgb(1, 128, 198);
  /* border-bottom: 1px solid orange !important; */
  box-shadow: none !important;
  padding: 0px !important;
}

.toolbar{
  flex-wrap: wrap;
  align-content: flex-start;
  height: fit-content;
}

.tool{
  flex-grow: 1;
}

.toolbar-title{
  text-align: left !important;
  color: rgb(199, 199, 199)!important;
}







/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 55%;
  display: flex;
}

/* The Close Button */
.close {
  display: flex;
  color: #aaa;
  margin-left: auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.popup-title{
  font-size: 1.6rem;
  color: rgb(1, 128, 198);
}

/* Set a style for popup buttons */
button:hover {
  opacity:1;
}

.popup-btns{
  display: flex;
  margin: auto;
  width: 70%;
}

/* Float cancel and delete buttons and add an equal width */
.safebtn, .deletebtn {
  width: 100%;
  align-self: center;
  padding: 12px 10px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  margin: 4px 12px;
  cursor: pointer;
  border-radius: 4px;
}

/* Add a color to the cancel button */
.safebtn {
  background-color: #f78f1f;
  color: white;
}


/* Add a color to the delete button */
.deletebtn {
  border: 1px solid #aaaaaa;
}

</style>