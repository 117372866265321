<template>
    <div class="werkveld">
      
      <div class="col-12">
        <Canvas/>
      </div>
      
    </div>
</template>

<script>
import Canvas from "./Canvas.vue";
export default {
  name: "App",

  components: {
    Canvas,
    // Toolbar
  }
};
</script>

<style scoped>
.werkveld{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

</style>