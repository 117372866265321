<template>
  <div class="container menuContainer" style="height: 100%;">
    <div class="row ">

      <div class="col-5 stappenlijst">
        <stappenlijst v-bind:nieuwe-stap.sync="geselecteerdeStapId"/>
      </div>

      <div class="col-7 stapinhoud">
        <component :is="dynamicComponent"></component>
      </div>
      
    </div>
  </div>
</template>

<script>
import Stappenlijst from "./Stappenlijst";
import StapAchtergrond from "./StapInhoud/StapAchtergrond";
import StapAttribuut from "./StapInhoud/StapAttribuut";
import StapPersoon from "./StapInhoud/StapPersoon";
import StapBeweging from "./StapInhoud/StapBeweging";
import StapActiviteit from "./StapInhoud/StapActiviteit";

export default {
  data: function() {
    return {
      geselecteerdeStapId: {
        default: 1,
      },
    };
  },
  components: {
    Stappenlijst,
    StapAchtergrond,
    StapAttribuut,
    StapPersoon,
    StapBeweging,
    StapActiviteit,
  },
  computed: {
    // dynamische component voor welke stap inhoud moet worden ingeladen
    dynamicComponent() {
      if (document.getElementById('isLesIndeling')){
        if (this.geselecteerdeStapId == 2){
          return "stap-activiteit";
        }else if (this.geselecteerdeStapId == 3) {
          return "stap-attribuut";
        } else if (this.geselecteerdeStapId == 4) {
          return "stap-persoon";
        } else if (this.geselecteerdeStapId == 5){
          return "stap-beweging";
        } else {
          return "stap-achtergrond"
        }
      }
      if (this.geselecteerdeStapId == 2) {
        return "stap-attribuut";
      } else if (this.geselecteerdeStapId == 3) {
        return "stap-persoon";
      } else if (this.geselecteerdeStapId == 4){
        return "stap-beweging";
      } else {
        return "stap-achtergrond"
      }
    },
  },
};
</script>
<style scoped>

.menuContainer {
  min-height: 100%;
  background-color: white;
  padding: 0;
}

.stapinhoud{
  padding: 0;
  background-color: #f3f2ef;
  box-shadow: 10px 0px 10px -10px #aaaaaa;
} 

.stappenlijst{
  padding: 0;
}

.row{
  min-height: 100%;
  margin: 0;
}

</style>
