<template>
  <div class="container appContainer">
    <div class="row">
      
      <div class="col-4 stappen-menu">
        <!-- Menu container -->

        <stappen-menu />
      </div>
      
      <div class="col-8 werkveld">
        <!-- werkblad-->

        <Werkveld />
      </div>

      
    </div>
  </div>
</template>



<script>
import {keybindHandlerInstantie} from "../business/keybindHandler";
import Werkveld from "./Werkblad/Werkveld.vue";
import StappenMenu from "./Menu/StappenMenu.vue";

export default {
  name: "App",

  components: {
    Werkveld,
    StappenMenu,
  },
  mounted: function () {
    keybindHandlerInstantie.init();
  }
};
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.appContainer {
  min-width: 100%;
  min-height: 100%;
  background-color: #f9f9f8;
  display: flex;
}


.stappen-menu{
  display: flex;
  min-height: 100%;
  padding: 0 !important;
}

.werkveld{
  margin: auto;
}

</style>