import StyleSheet from './style-sheet';
import { werkveldInstantie } from './werkveld'
import { Groep } from './cellen/groep';
import { Attribuut } from './cellen/attribuut';
import { Lijn } from './cellen/lijn';
//import * as mxgraph  from 'mxgraph';
const mx = require('mxgraph')({
    mxBasePath: '/mx/'
});

export default class Canvas {
    _graph;
    _container;
    _cellIDs;
    _styleSheet;
    _undoManager;

    _bladBreedte;
    _bladHoogte;
    _bladBreedteOud;
    _bladHoogteOud;
    _standaardBladBreedte = 800;
    _standaardBladHoogte = 600;
    _relativeX = 1;
    _relativeY = 1;

    constructor() {

    }

    //#region Graph setup

    /// <summary>
    ///    Creates graph and sets basic graph settings
    /// </summary>
    bouwGraph() {
        let graph = new mx.mxGraph(this._container);

        graph.setHtmlLabels(true);
        graph.setDropEnabled(true);
        graph.setSplitEnabled(false);
        graph.setEnabled(true);
        graph.setConnectableEdges(false);
        graph.setDisconnectOnMove(false);


        graph.resetEdgesOnConnect = false;
        mx.mxEdgeHandler.prototype.virtualBendsEnabled = true;

        new mx.mxRubberband(graph);


        mx.mxUtils.extractTextWithWhitespace = function (elems) {
            // Known block elements for handling linefeeds (list is not complete)
            var blocks = ['BLOCKQUOTE', 'DIV', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'OL', 'P', 'PRE', 'TABLE', 'UL'];
            var ret = [];

            function doExtract(elts) {
                // Single break should be ignored
                if (elts.length == 1 && (elts[0].nodeName == 'BR' ||
                    elts[0].innerHTML == '\n')) {
                    return;
                }

                for (var i = 0; i < elts.length; i++) {
                    var elem = elts[i];

                    // DIV with a br or linefeed forces a linefeed
                    if (elem.nodeName == 'BR' || elem.innerHTML == '\n' ||
                        ((elts.length == 1 || i == 0) && (elem.nodeName == 'DIV' &&
                            elem.innerHTML.toLowerCase() == '<br>'))) {
                        ret.push('\n');
                    }
                    else {
                        if (elem.nodeType === 3 || elem.nodeType === 4) {
                            if (elem.nodeValue.length > 0) {
                                ret.push(elem.nodeValue);
                            }
                        }
                        else if (elem.nodeType !== 8 && elem.childNodes.length > 0) {
                            doExtract(elem.childNodes);
                        }

                        if (i < elts.length - 1 && mx.mxUtils.indexOf(blocks, elts[i + 1].nodeName) >= 0) {
                            ret.push('\n');
                        }
                    }
                }
            }
            doExtract(elems);
            var elemsString = "";
            elems.forEach(elem => {
                if (elem.outerHTML != undefined) {
                    elemsString += elem.outerHTML;
                } else {
                    elemsString += elem.data;
                }
            })
            return elemsString;
        }



        mx.mxCellEditor.prototype.getInitialValue = function(state, trigger)
        {
            // de overschreven functie, hij moet niet de html tags aanpassen naar text
            // var result = mx.mxUtils.htmlEntities(this.graph.getEditingValue(state.cell, trigger), false);
            var result = this.graph.getEditingValue(state.cell, trigger);

            // Workaround for trailing line breaks being ignored in the editor
            if (!mx.mxClient.IS_QUIRKS && document.documentMode != 8 && document.documentMode != 9 &&
                document.documentMode != 10)
            {
                result = mx.mxUtils.replaceTrailingNewlines(result, '<div><br></div>');
            }
            return result.replace(/\n/g, '<br>');
        };

        return graph;
    }

    /// <summary>
    ///	Undo event roept functie aan om de changes te controleren
    /// </summary>
    onUndo() {
        var pointer = 0;
        for (var i = this._undoManager.history.length - 1; i >= 0; i--) {
            if (!(this._undoManager.history[i].undone)) {
                pointer = i;
                break;
            }
        }
        this._undoManager.undo();
        this.checkUndoManagerHistory(pointer);
    }

    /// <summary>
    ///	Redo event roept functie aan om de changes te controleren
    /// </summary>
    onRedo() {
        var pointer = 0;
        for (var i = 0; i < this._undoManager.history.length; i++) {
            if (!(this._undoManager.history[i].redone)) {
                pointer = i;
                break;
            }
        }
        this._undoManager.redo();
        this.checkUndoManagerHistory(pointer);
    }

    /// <summary>
    ///	Pointer om de juiste change te controleren
    /// </summary>
    checkUndoManagerHistory(pointer) {
        this._undoManager.history[pointer].changes.forEach(element => {
            if (element.child) return;

            // check of de cell nog wel onderwater staat maar niet in de graph
            if (werkveldInstantie.getAlleMxCells().includes(element.cell) && !(this._graph.getModel().getCell(element.cell.id))) {
                werkveldInstantie.verwijderCell([element.cell]);
            }
        })
    }

    // bouwt de undo manager voor mxGraph
    bouwUndoManager() {
        let undoManager = new mx.mxUndoManager();
        let listener = function (sender, evt) {
            undoManager.undoableEditHappened(evt.getProperty('edit'));
            // check if edges went out of the graph
            if (evt.properties.edit.changes[0].cell) {
                var cell = evt.properties.edit.changes[0].cell;
                if (cell.edge) {
                    canvasInstantie.CorrectEdge(cell);
                }
            }
        };
        this._graph.getModel().addListener(mx.mxEvent.UNDO, listener);
        this._graph.getView().addListener(mx.mxEvent.UNDO, listener);

        return undoManager;
    }

    /// <summary>
    ///	Check of een x binnen de bladbreedte zit
    /// </summary>
    /// <return>
    ///	bladbreedte wanneer de x niet binnen bladbreedte zit, zo wel dan return x
    /// </return>
    checkMaxXInGraph(x) {
        if (x > this._bladBreedte) {
            return this._bladBreedte
        }
        return x;
    }
    /// <summary>
    ///	Check of een y binnen de bladhoogte zit
    /// </summary>
    /// <return>
    ///	bladhoogte wanneer de y niet binnen bladhoogte zit, zo wel dan return y
    /// </return>
    checkMaxYInGraph(y) {
        if (y > this._bladHoogte) {
            return this._bladHoogte;
        }
        return y;
    }
    /// <summary>
    ///	Check of een x binnen het blad zit
    /// </summary>
    /// <return>
    ///	0 wanneer de x niet binnen het blad zit, zo wel dan return x
    /// </return>
    checkMinXInGraph(x) {
        if (x < 0) {
            return 0
        }
        return x;
    }
    /// <summary>
    ///	Check of een y binnen het blad zit
    /// </summary>
    /// <return>
    ///	0 wanneer de y niet binnen het blad zit, zo wel dan return y
    /// </return>
    checkMinYInGraph(y) {
        if (y < 0) {
            return 0;
        }
        return y;
    }
    /// <summary>
    ///	Functie om te controleren of alle punten van een edge wel binnen het blad zijn
    /// </summary>
    /// <param name='cell'>De edge die gecheckt moet worden</param>
    CorrectEdge(cell) {
        // check groter dan het blad
        cell.geometry.sourcePoint.x = this.checkMaxXInGraph(cell.geometry.sourcePoint.x);
        cell.geometry.sourcePoint.y = this.checkMaxYInGraph(cell.geometry.sourcePoint.y);
        cell.geometry.targetPoint.x = this.checkMaxXInGraph(cell.geometry.targetPoint.x);
        cell.geometry.targetPoint.y = this.checkMaxYInGraph(cell.geometry.targetPoint.y);

        // check kleiner dan het blad
        cell.geometry.sourcePoint.x = this.checkMinXInGraph(cell.geometry.sourcePoint.x);
        cell.geometry.sourcePoint.y = this.checkMinYInGraph(cell.geometry.sourcePoint.y);
        cell.geometry.targetPoint.x = this.checkMinXInGraph(cell.geometry.targetPoint.x);
        cell.geometry.targetPoint.y = this.checkMinYInGraph(cell.geometry.targetPoint.y);

        if (cell.geometry.points != null && cell.geometry.points.length > 0) {
            cell.geometry.points.forEach(point => {
                point.x = this.checkMaxXInGraph(point.x);
                point.y = this.checkMaxYInGraph(point.y);
                point.x = this.checkMinXInGraph(point.x);
                point.y = this.checkMinYInGraph(point.y);
            })
        }
        this._graph.refresh();
    }

    /// <summary>
    ///    Sets the container in which the graph will be shown and calls the plattegrondInit() function
    /// </summary>
    /// <param name='container'>HTML element in which the graph will be shown</param>
    zetContainer(container) {
        this._container = container;
        // new mxgraph.mxgraph(container);
        this._cellIDs = new Map();
        this._styleSheet = new StyleSheet();
        this._graph = this.bouwGraph();
        this._undoManager = this.bouwUndoManager();
        this.plattegrondInit();
        // maak stijlen voor de attributen
        werkveldInstantie.zetAttribuutStijlen();

        // zet cellen in groepen zodat je ze niet kan aanpassen of verplaatsen
        this._graph.isValidDropTarget = () => false;

        canvasInstantie.zetGraphBounds();
    }

    //#region Alle plaats mx cellen functies:

    /// <summary>
    ///	Functie die een attribuut op het midden van het veld plaatst bij het klikken op een attribuut
    /// </summary>
    /// <param name='naam'> De naam van het attribuut</param>
    /// <param name='tekst'> De tekst voor de mxCell (meestal null)</param>
    plaatsAttribuutBijKlik(naam, tekst) {
        var graph = this._graph;
        // pak x en y
        var x = parseFloat(this._bladBreedte / 2) + 0.00000001;
        var y = parseFloat(this._bladHoogte / 2) + 0.00000001;

        var object = werkveldInstantie.getJsonObject(naam).returnItem;
        var categorieNaam = werkveldInstantie.getJsonObject(naam).categorieName;
        this.plaatsEenAttribuut(graph, tekst, categorieNaam, object, x, y)
    }

    /// <summary>
    ///	functie wordt aangeroepen wanneer er een attribuut op het veld wordt gesleept
    /// </summary>
    /// <param name='stijl'> De stijl die bij het attribuut hoort</param>
    /// <param name='object'> Het Json object wat bij het attribuut hoort</param>
    /// <param name='categorieName'> De categorie waarin het attribuut hoort</param>
    /// <param name='tekst'> De tekst die in de mxCell geplaatst moet worden</param>
    maakPlaatsAttribuut(stijl, object, categorieName, tekst) {
        return function (graph, evt, cell, x, y) {

            canvasInstantie.plaatsEenAttribuut(graph, tekst, categorieName, object, x, y)
        }
    }

    /// <summary>
    ///	Functie die een attribuut plaatst op de grafiek
    /// </summary>
    /// <param name='graph'>De grafiek waarop het attribuut geplaatst wordt</param>
    /// <param name='tekst'>De teskt die in het attribuut moet staan</param>
    /// <param name='categorieNaam'>De categorie naam van het attribuut</param>
    /// <param name='object'>Het object met data van het attribuut</param>
    /// <param name='x'>De x coordinaat</param>
    /// <param name='y'>De y coordinaat</param>

    plaatsEenAttribuut(graph, tekst, categorieName, object, x, y) {
        var attribuut;
        var parent = graph.getDefaultParent();
        var naam = object.naam;

        if (categorieName == "bewegingen" && !object.static) {
            var beweging = new Lijn(werkveldInstantie, object, object.stijl);

            var edge = null;
            graph.getModel().beginUpdate();
            try {
                edge = new mx.mxCell(null, new mx.mxGeometry(0, 0, 50, 50), beweging._stijl);
                if (beweging._naam.includes("halve-ronde")) {
                    edge.geometry.points = [new mx.mxPoint(x - 50, y - 50), new mx.mxPoint(x + 50, y - 50)];
                } else if (beweging._naam.includes("ronde")) {
                    edge.geometry.points = [new mx.mxPoint(x, y - 50)];
                }

                edge.geometry.setTerminalPoint(new mx.mxPoint(x - 50, y), true);
                edge.geometry.setTerminalPoint(new mx.mxPoint(x + 50, y), false);

                edge.edge = true;

                beweging._x = x;
                beweging._y = y;
                beweging._z = werkveldInstantie._cellen.length;
                beweging._geometry = edge.geometry;
                werkveldInstantie.saveAttribuut(beweging, edge);

                edge = graph.addCell(edge);
                graph.fireEvent(new mx.mxEventObject('cellsInserted', 'cells', [edge]));
            }
            finally {
                graph.getModel().endUpdate();
            }
            graph.setSelectionCell(edge);
        } else {
            attribuut = new Attribuut(werkveldInstantie, object, werkveldInstantie._htmlElementStyles.get(naam));
            if (tekst != null) {
                attribuut._label = tekst;
            }
            var vertex = null;
            graph.getModel().beginUpdate();
            try {
                vertex = graph.insertVertex(parent, null, tekst, x, y, attribuut.getBreedte() * canvasInstantie._relativeX, attribuut.getHoogte() * canvasInstantie._relativeY, attribuut.getStijlnaam());
            }
            finally {
                graph.getModel().endUpdate();
            }
            graph.setSelectionCell(vertex);
            attribuut._x = x;
            attribuut._y = y;
            attribuut._z = werkveldInstantie._cellen.length;
            werkveldInstantie.saveAttribuut(attribuut, vertex);
        }
    }

    /// <summary>
    ///	plaats mx cellen vanuit een attributen array
    /// </summary>
    /// <param name='attributen'> Array met attributen die geplaatst moeten worden</param>
    /// <param name='activiteitBool'> Boolean die zegt of de geplaatste attributen samen geselecteerd moeten worden en als groep geplaatst moeten worden</param>
    placeMxCells(attributen, activiteitBool) {
        var parent = this._graph.getDefaultParent();
        var vertex = null;
        var vertexes = [];

        attributen.forEach(element => {
            this._graph.getModel().beginUpdate();
            try {
                if (element._instantieVan == 'attribuut' || element._instantieVan == 'persoon') {
                    vertex = this._graph.insertVertex(parent, null, element._label, parseFloat(element._x) + 0.000001, parseFloat(element._y) + 0.000001, element.getBreedte(), element.getHoogte(), element.getStijlnaam());
                } else {
                    vertex = new mx.mxCell(null, new mx.mxGeometry(0, 0, 50, 50), werkveldInstantie.getJsonObject(element._naam).returnItem.stijl);
                    vertex.geometry.setTerminalPoint(new mx.mxPoint(element._startPunt.x, element._startPunt.y), true);
                    vertex.geometry.setTerminalPoint(new mx.mxPoint(element._eindPunt.x, element._eindPunt.y), false);
                    vertex.geometry.points = element._punten;
                    vertex.edge = true;
                    vertex = this._graph.addCell(vertex);
                    this._graph.fireEvent(new mx.mxEventObject('cellsInserted', 'cells', [vertex]));
                }
                vertexes.push(vertex);
            }
            finally {
                this._graph.getModel().endUpdate();
            }
            werkveldInstantie.saveAttribuut(element, vertex);
        })
        if (activiteitBool) {
            this._graph.setSelectionCells(vertexes);
            this.groeperen();
        }
    }

    //#endregion

    groeperen() {
        var selectedCells = this._graph.getSelectionCells();
        if (!selectedCells.length > 0) return;

        // check of mensen groepen van groepen willen maken -> mag niet
        selectedCells.forEach(element => {
            if (werkveldInstantie._groepen.includes(werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(element)))) {
                return;
            }
        });
        let group = this._graph.groupCells(null, 0, selectedCells);

        var z = 0;
        selectedCells.forEach(element => {
            if (element.z > z) {
                z = element.z;
            }
        })
        var groep = new Groep(selectedCells, "groep-stijl", z);
        werkveldInstantie._groepen.push(groep);
        werkveldInstantie._celIDs.set(groep._id, group);
        let stijl = this._styleSheet.getGroepStijl();
        this._graph.getStylesheet().putCellStyle("groep-stijl", stijl);
        group.style = "groep-stijl"

        // Selecteer nieuw aangemaakte groep
        let newGroup = this._graph.getSelectionCells()[0].parent;
        this._graph.setSelectionCell(newGroup)

        this._graph.orderCells(true, this.getCellenInVolgorde());

        this._graph.refresh();
    }

    degroeperen() {
        var selectedCells = this._graph.getCellsForUngroup();
        if (!selectedCells.length > 0) return;

        selectedCells.forEach(cell => {
            if (cell.children) {
                cell.children.forEach(child => {
                    werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(child))._groepId = null;
                })
            }
            this._graph.ungroupCells(null, 0, cell);
        });

        this._graph.orderCells(true, this.getCellenInVolgorde());
    }

    zetAchtergrond(url) {
        this._graph.setBackgroundImage(new mx.mxImage(url, this._bladBreedte, this._bladHoogte));
        this._graph.refresh();
    }

    /// <summary>
    /// Zet rotation voor cellen aan
    /// </summary>
    plattegrondInit() {
        mx.mxVertexHandler.prototype.rotationEnabled = true;
        this._graph.selectionModel.addListener(mx.mxEvent.CHANGE, (_sender, evt) => {
            this.CellSelection(evt)
        });

        this._graph.view.addListener(mx.mxEvent.TRANSLATE, () => {
            this._graph.view.translate = new mx.mxPoint(0, 0);
            this._graph.refresh();
        });

        mx.mxPanningHandler.prototype.maxScale = 1;
        mx.mxPanningHandler.prototype.minScale = 1;


        mx.mxConstants.VERTEX_SELECTION_COLOR = '#0180C6';
        mx.mxConstants.EDGE_SELECTION_COLOR = '#0180C6';
        mx.mxConstants.HANDLE_FILLCOLOR = '#0180C6';
        // mx.mxConstants.HANDLE_SIZE = 12;

        mx.mxVertexHandler.prototype.rotateVertex = function (me) {
            var point = new mx.mxPoint(me.getGraphX(), me.getGraphY());
            var dx = this.state.x + this.state.width / 2 - point.x;
            var dy = this.state.y + this.state.height / 2 - point.y;

            var raster;
            this.currentAlpha = (dx != 0) ? Math.atan(dy / dx) * 180 / Math.PI + 90 : ((dy < 0) ? 180 : 0);

            if (dx > 0) {
                this.currentAlpha -= 180;
            }

            // bug fix rotation
            // this.currentAlpha -= this.startAngle;

            // Rotation raster
            if (this.rotationRaster && this.graph.isGridEnabledEvent(me.getEvent())) {
                dx = point.x - this.state.getCenterX();
                dy = point.y - this.state.getCenterY();
                var dist = Math.sqrt(dx * dx + dy * dy);

                if (dist - this.startDist < 2) {
                    raster = 15;
                }
                else if (dist - this.startDist < 25) {
                    raster = 5;
                }
                else {
                    raster = 1;
                }

                this.currentAlpha = Math.round(this.currentAlpha / raster) * raster;
            }
            else {
                this.currentAlpha = this.roundAngle(this.currentAlpha);
            }

            this.selectionBorder.rotation = this.currentAlpha;

            this.selectionBorder.redraw();

            if (this.livePreviewActive) {
                this.redrawHandles();
            }
        };

        // zet de scroll x en y standaard op 0

        mx.mxUtils.getDocumentScrollOrigin = function () {
            var x = 0;
            var y = 0;
            return new mx.mxPoint(x, y);
        };
    }
    //#endregion

    //#region Achtergrondcellen setup en functies

    veranderCellAchtergrondSvg(dataUrl) {
        this._graph.setBackgroundImage(new mx.mxImage(dataUrl, this._bladBreedte, this._bladHoogte));
        this._graph.refresh();
    }

    SetHandleSize(size) {
        mx.mxConstants.HANDLE_SIZE = size;
    }

    /// <summary>
    ///    Sets correct cell hierarchy for the background cells
    /// </summary>
    zetCellHierarchie() {
        var achtergrondCell = this._graph.model.getCell('background-cell');
        var lijnensetCell = this._graph.model.getCell('lijnenset-cell');
        var indelingCell = this._graph.model.getCell('indeling-cell');
        var gridCell = this._graph.model.getCell('grid-cell');
        this._graph.orderCells(true, [achtergrondCell, lijnensetCell, indelingCell, gridCell])
    }

    //#endregion

    //#region Werkbalk functies 

    /// <summary>
    ///    Removes all selected cells from the graph
    /// </summary>
    verwijderCell() {
        var selectedCells = this._graph.getSelectionCells();
        if (selectedCells.length != 0) {
            for (let i = 0; i < selectedCells.length; i++) {
                if (selectedCells[i].children) {
                    werkveldInstantie.verwijderCell(selectedCells[i].children);
                }
            }
            werkveldInstantie.verwijderCell(selectedCells);
            this._graph.removeCells(selectedCells);
        }
    }

    /// <summary>
    ///    Removes given cell(s) from the graph
    /// </summary>
    /// <param name='id'>ID('s) of cell(s) to be removed</param>
    removeCell(id) {
        this._graph.model.remove(this._graph.model.getCell(id));
        this._graph.refresh();
    }

    /// <summary>
    ///    Duplicates the selected cells
    /// </summary>
    cloneCells() {

        var selectedCells = this._graph.getSelectionCells();
        var cloneCells = this._graph.cloneCells(selectedCells);

        for (var i = 0; i < selectedCells.length; i++) {
            if (selectedCells[i].children) {
                for (var j = 0; j < selectedCells[i].children.length; j++) {
                    werkveldInstantie.kopieerCell(cloneCells[i].children[j], selectedCells[i].children[j]);
                }
            }
            werkveldInstantie.kopieerCell(cloneCells[i], selectedCells[i]);
        }
        this._graph.addCells(cloneCells);
        this._graph.setSelectionCells(cloneCells);
    }

    hierarchieNaarVoren() {
        // krijg geselecteerde cellen
        var selectedCells = this.getSelectedCellsOrderedByZ();
        var selectedCellsZ = this.getSelectedCellsOrderedByZ();
        var selectedParentAndChildrenCellsZ = this.getSelectedCellsOrderedByZ();

        // zet de geselecteerde kinderen als geselecteerd
        selectedCells.forEach(element => {
            if (element.children) {
                element.children.forEach(child => {
                    selectedCellsZ.push(child);
                    selectedParentAndChildrenCellsZ.push(child);
                })
                selectedCellsZ.splice(selectedCellsZ.indexOf(element), 1);
            }
        });

        // krijg niet geselecteerde cellen
        var unSelectedCells = this.getUnSelectedCellsOrderedByZ(selectedParentAndChildrenCellsZ);
        var unSelectedCellsZ = this.getUnSelectedCellsOrderedByZ(selectedParentAndChildrenCellsZ);

        // zet de niet geselecteerde kinderen als niet geselecteerd en haal parent eruit
        unSelectedCells.forEach(element => {
            if (element.children) {
                unSelectedCellsZ.splice(unSelectedCells.indexOf(element), 1);
            }
        })

        this.updateZIndex(unSelectedCellsZ, 0);
        var counter = 0;
        unSelectedCellsZ.forEach(element => {
            if (element.children) {
                counter += element.children.length;
            } else {
                counter++;
            }
        });
        this.updateZIndex(selectedCellsZ, counter);


        this.updateParent(selectedCellsZ);
        this.updateParent(unSelectedCellsZ);

        this._graph.cellsOrdered(selectedCells, false);
    }

    hierarchieNaarAchter() {
        // krijg geselecteerde cellen
        var selectedCells = this.getSelectedCellsOrderedByZ();
        var selectedCellsZ = this.getSelectedCellsOrderedByZ();
        var selectedParentAndChildrenCellsZ = this.getSelectedCellsOrderedByZ();

        // zet de geselecteerde kinderen als geselecteerd
        selectedCells.forEach(element => {
            if (element.children) {
                element.children.forEach(child => {
                    selectedCellsZ.push(child);
                    selectedParentAndChildrenCellsZ.push(child);
                })
                selectedCellsZ.splice(selectedCellsZ.indexOf(element), 1);
            }
        });

        // krijg niet geselecteerde cellen
        var unSelectedCells = this.getUnSelectedCellsOrderedByZ(selectedParentAndChildrenCellsZ);
        var unSelectedCellsZ = this.getUnSelectedCellsOrderedByZ(selectedParentAndChildrenCellsZ);

        // zet de niet geselecteerde kinderen als niet geselecteerd en haal parent eruit
        unSelectedCells.forEach(element => {
            if (element.children) {
                unSelectedCellsZ.splice(unSelectedCells.indexOf(element), 1);
            }
        })

        this.updateZIndex(selectedCellsZ, 0);
        var counter = 0;
        selectedCellsZ.forEach(element => {
            if (element.children) {
                counter += element.children.length;
            } else {
                counter++;
            }
        });

        this.updateZIndex(unSelectedCellsZ, counter);

        this.updateParent(selectedCellsZ);
        this.updateParent(unSelectedCellsZ);

        this._graph.cellsOrdered(selectedCells, true);
    }

    getCellenInVolgorde() {
        var cells = werkveldInstantie.getAlleMxCells();

        var attributen = [];
        cells.forEach(element => {
            attributen.push(werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(element)));
        });

        var gesorteerdeAttributen = attributen.sort(function (a, b) { return a._z - b._z });

        var cellen = [];
        gesorteerdeAttributen.forEach(element => {
            cellen.push(werkveldInstantie._celIDs.get(element._id))
        });

        return cellen;
    }

    updateParent(cells) {
        cells.forEach(cell => {
            if (cell.parent) {
                if (cell.parent.style) {
                    var parent = cell.parent;
                    werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(parent))._z = werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(cell))._z;
                }
            }
        })
    }

    CellSelection(evt) {
        // Cel(len) die zijn toegevoegd aan de selectie
        let selectedCells = evt.getProperty('removed');
        let orphanCells = [];
        let group = null;

        if (selectedCells == null) return;

        for (let i = 0; i < selectedCells.length; i++) {
            let parentId = selectedCells[i].parent.id;
            if (parentId != 1) {
                group = selectedCells[i];
            } else {
                orphanCells.push(selectedCells[i]);
            }
        }

        if (orphanCells.length > 0) {
            if (group != null) this._graph.setSelectionCells(orphanCells);
            return
        }
        if (group != null) this._graph.setSelectionCell(group.parent);
    }

    getSelectedCellsOrderedByZ() {
        var selectedCells = this._graph.getSelectionCells();
        selectedCells.sort((a, b) =>
            (werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(a))._z
                > werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(b))._z)
                ? 1 : -1);
        return selectedCells;
    }

    getUnSelectedCellsOrderedByZ(selectedCells) {
        var unSelectedCells = werkveldInstantie.getAlleMxCells().filter(e => !selectedCells.includes(e));
        unSelectedCells.sort((a, b) =>
            (werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(a))._z
                > werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(b))._z)
                ? 1 : -1);

        return unSelectedCells;
    }

    updateZIndex(cells, number) {
        cells.forEach(element => {
            werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(element))._z = number;
            number++;
        })
    }

    //#endregion

    //#region Responsive Graph functies

    calcRelativity() {
        this._relativeX = (100 * this._bladBreedte / this._standaardBladBreedte) / 100;
        this._relativeY = (100 * this._bladHoogte / this._standaardBladHoogte) / 100;
    }

    /// <summary>
    ///    Sets _bladBreedte and _bladHoogte to the current container size and saves the previous size
    /// </summary>
    zetBladAfmetingen() {
        this._bladBreedteOud = this._bladBreedte;
        this._bladHoogteOud = this._bladHoogte;

        this._bladBreedte = document.getElementById("canvas").getBoundingClientRect().width;
        this._bladHoogte = document.getElementById("canvas").getBoundingClientRect().height;
        this.calcRelativity()
    }

    /// <summary>
    ///    Resizes and reshapes cells according to canvas size
    /// </summary>
    correctCellsToCanvas() {
        this.zetBladAfmetingen();

        let vertices = werkveldInstantie.getAlleMxCells();

        let relativeLength = 100 * this._bladBreedte / this._bladBreedteOud;
        let relativeHeight = 100 * this._bladHoogte / this._bladHoogteOud;

        for (let i = 0; i < vertices.length; i++) {
            let cell = vertices[i];
            var attribuut = werkveldInstantie.getAttribuutMetID(werkveldInstantie.getKeyVanWaardeInMap(cell));

            this.calcNewCellPos(attribuut, cell, relativeLength, relativeHeight);
            if (attribuut._instantieVan != "beweging") {
                this.calcNewCellSize(cell, relativeLength, relativeHeight);
            }

        }
        this._graph.refresh();
    }

    /// <summary>
    ///    Calculates the new cell position according to the change in canvas size
    /// </summary>
    /// <param name='id'>ID of the cell of which the new position is calculated</param>
    calcNewCellPos(attribuut, cell, relativeX, relativeY) {
        if (cell == null) return;

        if (attribuut._instantieVan != 'beweging') {
            cell.geometry.x = cell.geometry.x * (relativeX / 100);
            cell.geometry.y = cell.geometry.y * (relativeY / 100);
        } else {
            cell.geometry.sourcePoint.x = cell.geometry.sourcePoint.x * (relativeX / 100);
            cell.geometry.sourcePoint.y = cell.geometry.sourcePoint.y * (relativeY / 100);

            cell.geometry.targetPoint.x = cell.geometry.targetPoint.x * (relativeX / 100);
            cell.geometry.targetPoint.y = cell.geometry.targetPoint.y * (relativeY / 100);

            if (cell.geometry.points) {
                cell.geometry.points.forEach(point => {
                    point.x = point.x * (relativeX / 100);
                    point.y = point.y * (relativeY / 100)
                })
            }
        }
    }

    /// <summary>
    ///    Calculates the new cell size according to the change in canvas size
    /// </summary>
    /// <param name='id'>ID of the cell of which the new size is calculated</param>
    calcNewCellSize(cell, relativeWidth, relativeHeight) {
        if (cell == null) return

        cell.geometry.width = cell.geometry.width * (relativeWidth / 100);
        cell.geometry.height = cell.geometry.height * (relativeHeight / 100);
    }

    /// <summary>
    ///    Sets the max graph size to the size of the graph container
    /// </summary>
    zetGraphBounds() {
        this.zetBladAfmetingen();
        let rect = new mx.mxRectangle(0, 0, this._bladBreedte, this._bladHoogte);
        this._graph.maximumGraphBounds = rect;
    }

    /// <summary>
    ///	Verplaats cellen met keyinput, zet pixels +5 in de goede richting
    /// </summary>
    /// <param name='direction'>richting waarin de cellen verplaatst moeten worden</param>
    moveCellByKey(direction) {
        this.zetGraphBounds();
        var cells = this._graph.getSelectionCells();
        switch (direction) {
            case 'left': {
                cells.forEach(element => {
                    if (!((element.geometry.x - 5) < 0)) {
                        element.geometry.x -= 5;
                    }
                })
                break;
            }
            case 'right': {
                cells.forEach(element => {
                    if (!((element.geometry.x + 5 + element.geometry.width) > this._bladBreedte)) {
                        element.geometry.x += 5;
                    }
                })
                break;
            }
            case 'up': {
                cells.forEach(element => {
                    if (!((element.geometry.y - 5) < 0)) {
                        element.geometry.y -= 5;
                    }
                })
                break;
            }
            case 'down': {
                cells.forEach(element => {
                    if (!((element.geometry.y + 5 + element.geometry.height) > this._bladHoogte)) {
                        element.geometry.y += 5;
                    }
                })
                break;
            }
        }
        this._graph.refresh();
    }

    //#endregion

}

export const canvasInstantie = new Canvas();
