<template>
    <div class="container stapinhoud-container">
        <span class="span-header unselectable">Teken pijlen en looplijnen op het veld</span>

        <div class="dropdown-container" id="dropdown-container">
            <div class="accordion" v-for="(item) in bewegingen" v-bind:key="item.naam" v-bind:id="'cat-'+item.id">
                <div v-on:click="schakelDropdown(item.id)" class="accordion-row" v-bind:title="item.naam">
                    <a  class="accordion-button unselectable textOverflow" >{{item.naam}}</a>
                    <div class="accordion-icons">
                        <font-awesome-icon class="down-icon" v-bind:id="'chevron-down-icon-'+item.id" icon="chevron-down" />
                        <font-awesome-icon v-bind:id="'chevron-up-icon-'+item.id" icon="chevron-up" class="up-icon hidden" />
                    </div>
                </div>
                <div class="accordion-content" v-bind:id="'cat-content-'+item.id">
                    <!-- Subcategorieen -->
                    <div class="subcats">
                        <div v-for="subcategory in item.subcategorieen" v-bind:key="subcategory.naam" v-bind:id="'subcat-'+subcategory.id">
                            <div class="accordion-row" v-on:click="schakelSubcategorie(subcategory.id)" v-bind:title="subcategory.naam">
                                <a  class="accordion-button-subcat unselectable textOverflow">{{subcategory.naam}}</a>
                                <div class="accordion-icons">
                                    <font-awesome-icon class="down-icon" v-bind:id="'subcat-chevron-down-icon-'+subcategory.id" icon="chevron-down" />
                                    <font-awesome-icon v-bind:id="'subcat-chevron-up-icon-'+subcategory.id" icon="chevron-up" class="up-icon hidden" />
                                </div>
                            </div>
                            <!-- Objecten van subcategorieen -->
                            <div v-bind:id="'sub-content-'+subcategory.id" class="accordion-content">
                                <div class="accordion-div attribuutIcoon" v-for="object in subcategory.objecten" v-bind:key="object.naam">
                                    <sleepbaar-lijst-component :item="object" :zoek="'subcat-attribuut-'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Losse objecten in categorie -->
                    <div class="accordion-div attribuutIcoon" v-for="object in item.objecten" v-bind:key="object.naam">
                    <sleepbaar-lijst-component :item="object" :zoek="'attribuut-'"/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import bewegingenJson from '../../../assets/bewegingen.json';
import DropdownHandler from '../../../business/dropdownHandler';
import SleepbaarLijstComponent from './SleepbaarLijstComponent.vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add([faChevronUp, faChevronDown]);

export default {
    data() {
        return {
            search: '',
            // TODO items inladen vanuit bewegingen lijst
            bewegingen: bewegingenJson.bewegingen,
            dropdownHandler: null,
        }
    },
    components: {
        SleepbaarLijstComponent,
        FontAwesomeIcon,
    },
    mounted: function () {
        this.dropdownHandler = new DropdownHandler()
    },
    methods: {
        schakelDropdown(id) {
            this.dropdownHandler.schakelDropdown(id)
        },
        schakelSubcategorie(id) {
            this.dropdownHandler.schakelSubcategorie(id)
        },
    }
}
</script>

<style scoped>
@import '/css/stapinhoud.css';
@import '/css/dropdown.css';



.zoek-input {
    display: flex;
    text-align: center;
    max-width: 100%;
    font-size: 0.9rem;
}


.accordion-content {
    display: none;
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    color: black;
    font-size: 0.8rem;
    background: white;
    padding-bottom: 0.3rem;
}


.search-list {
    overflow-y: auto;
    max-height: 25rem;
    background: white;
    text-align: left;
    padding: 0.5rem;
}
.list-content {
    display: block;
    color: black;
    font-size: 0.8rem;
    width: 100%;
}
.show {
    display: block;
    background: white;
}
.active {
    border: 1px solid rgb(1, 128, 198);
    color: rgb(1, 128, 198);
    padding-top: 0.5rem;
    box-shadow: 0 0 5px #aaaaaa;
}

.accordion-div {
    display: flex;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
}

.accordion-div-span {
    width: 67%;
    margin-left: 5px;
    margin-right: 5px;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
</style>