import { canvasInstantie } from "./canvas";
export default class KeybindHandler {
    _keysPressed;
    constructor() {
        this._keysPressed = [];
    }

    /// <summary>
    ///	Event voor keybindings
    /// </summary>
    init(){
        document.onkeydown = (keyDownEvent) => {
            this._keysPressed[keyDownEvent.key] = true;

            if (this._keysPressed["Control"]){
                if (this._keysPressed['z']){
                    canvasInstantie.onUndo();
                    return;
                }
                if (this._keysPressed['y']){
                    canvasInstantie.onRedo();
                    return;
                }
                if (this._keysPressed['c']){
                    canvasInstantie.cloneCells();
                    return;
                }
            }
            if (this._keysPressed['Delete'] || this._keysPressed['Backspace']){
                // check if cell is a tekst cell
                if (!this.isTekstVlak()){
                    canvasInstantie.verwijderCell();
                }
                return;
            }
            if (this._keysPressed['ArrowLeft']){
                if (!this.isTekstVlak()){
                    canvasInstantie.moveCellByKey('left');
                }
            }
            if (this._keysPressed['ArrowRight']){
                if (!this.isTekstVlak()){
                    canvasInstantie.moveCellByKey('right');
                }
            }
            if (this._keysPressed['ArrowUp']){
                if (!this.isTekstVlak()){
                    canvasInstantie.moveCellByKey('up');
                }
            }
            if (this._keysPressed['ArrowDown']){
                if (!this.isTekstVlak()){
                    canvasInstantie.moveCellByKey('down');
                }
            }
        }
        document.onkeyup = (keyUpEvent) => {
            this._keysPressed[keyUpEvent.key] = false;
        }
    }
    /// <summary>
    ///	Controleerd of een tekstvlak is geselecteerd
    /// </summary>
    /// <return>
    ///	true wanneer een tekstvlak is geselecteerd anders false
    /// </return>
    isTekstVlak(){
        if ((canvasInstantie._graph.getSelectionCells().length == 1)){
            if (canvasInstantie._graph.getSelectionCells()[0].style.includes('stijl_tekstvlak')){
                return true;
            }
        }
        return false;
    }
}
export const keybindHandlerInstantie = new KeybindHandler();