<template>
  <div class="accordion" id="select-container">
    <div class="accordion-row" v-on:click="toggleMenu('inklapbaar-gedeelte')">
        <a class="accordion-button textOverflow">Achtergrond</a>
        <div class="accordion-icons">
          <font-awesome-icon id="chevron-down-icon" icon="chevron-down" />
          <font-awesome-icon id="chevron-up-icon" icon="chevron-up" class="hidden" />
        </div>
    </div>
    
      <div id="inklapbaar-gedeelte" class="hidden inklapbaar-gedeelte">

        <div class="selectbox-container">
          <select id="achtergrond-selector" class="achtergrond-html-select" @change="selectAchtergrond($event)" >
            <option value="geen-achtergrond unselectable">Geen achtergrond</option>
            <option v-for="achtergrond in achtergronden" v-bind:key="achtergrond.id" v-bind:value="achtergrond.url">
            {{ achtergrond.naam }}
            </option>
          </select>
          <font-awesome-icon id="chevron-down-icon" icon="chevron-down" class="selectbox-chevron"/>
        </div>

        <div class="vakjes-container">
          <div class="vakje" v-for="kleur in kleuren" v-bind:key="kleur.id">
            <button v-bind:title="kleur.naam" v-bind:style="'background-color:' + kleur.hexCode + ';'" class="achtergrond-vakje"
              v-on:click="selectAchtergrondKleur(kleur.hexCode)"
            ></button>
          </div>
        </div>
      
        <button class="verwijder-btn unselectable" v-on:click="verwijderAchtergrond()">
          Verwijder achtergrond
        </button>
      </div>
  </div>
</template>

<script>
import { canvasInstantie } from "../../../../business/canvas";
import { backgroundHandlerInstantie } from "../../../../business/backgroundHandler";
import achtergronden from "../../../../assets/achtergronden.json";
import kleuren from "../../../../assets/kleuren.json";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add([faChevronUp, faChevronDown]);

export default {
  data() {
    return {
      canvas: null,
      achtergronden: achtergronden,
      kleuren: kleuren,
    };
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    toggleMenu(id) {
      let inklapbaarMenu = document.getElementById(id);
      let upIcon = document.getElementById("chevron-up-icon");
      let downIcon = document.getElementById("chevron-down-icon");
      let achtergrondSelectDiv = document.getElementById("select-container");

      upIcon.classList.toggle("hidden");
      downIcon.classList.toggle("hidden");
      inklapbaarMenu.classList.toggle("hidden");
      achtergrondSelectDiv.classList.toggle("achtergrond-select-active");
    },
    async selectAchtergrondKleur(kleur) {
      backgroundHandlerInstantie._achtergrond = backgroundHandlerInstantie.createColorBackgroundSvg(kleur);
      backgroundHandlerInstantie._backgroundKleur = kleur;

      if (backgroundHandlerInstantie._gridEnabled) await backgroundHandlerInstantie.setGrid();

      let dataUrl = await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte)
      this.veranderAchtergrondFoto(dataUrl);
      document.getElementById("achtergrond-selector").selectedIndex = 0;
    },
    async selectAchtergrond($event) {
      backgroundHandlerInstantie._backgroundKleur = "";
      let value = $event.target.value

      if (value == "geen-achtergrond") {
        backgroundHandlerInstantie._achtergrond = "";
      } else {
        backgroundHandlerInstantie._achtergrond = `/img/achtergrondSvgs/${value}`
      }

      if (backgroundHandlerInstantie._gridEnabled) await backgroundHandlerInstantie.setGrid();

      let dataUrl = await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte)
      this.veranderAchtergrondFoto(dataUrl);
    },
    async verwijderAchtergrond() {
      backgroundHandlerInstantie._backgroundKleur = "";
      if (backgroundHandlerInstantie._gridEnabled) await backgroundHandlerInstantie.setGrid();
      backgroundHandlerInstantie._achtergrond = "";
      let dataUrl = await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte)
      this.veranderAchtergrondFoto(dataUrl)
    },
    veranderAchtergrondFoto(dataUrl) {
      canvasInstantie.veranderCellAchtergrondSvg(dataUrl);
    },
  },
};
</script>
<style scoped>

@import '/css/dropdown.css';

.unselectable{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.achtergrond-select a b {
  color: #777777;
}

.achtergrond-select-active {
  border-color: #0180c6;
}


.achtergrond-vakje {
  border: 1px solid gray;
  height: 100%;
  width: 100%;
  margin-bottom: 0.5rem;
}

.vakje{
  height: 2rem;
  width: 10px;
  min-width: 25%;
  padding: 3px;
}

.vakjes-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.3rem;
  margin-top: 0.75rem;
  justify-content: flex-start;
}

.selectbox-container {
  padding-top: 0.15rem;
  width: 100%;
  border: 1px solid gray;
  position: relative;
  height: 1.5rem;
}

.achtergrond-html-select {
  z-index: 10;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 25%;
  position: absolute;
}

.selectbox-chevron {
  float: right;
  margin-right: 0.75rem;
  margin-top: 0.1rem;
  color: #777777;
}

.separator {
  background-color: gray;
  width: 100%;
  height: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.inklapbaar-gedeelte {
  padding-bottom: 0;
  margin-top: 1rem;
  width: 100%;
  padding: 0.3rem;
}

.hidden {
  display: none;
}

.verwijder-btn{
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  border: 0.1rem solid grey;
  color: black;
}

.textOverflow {
    white-space: nowrap; 
    overflow: hidden;
}
</style>
