<template>
  <div class="container stapinhoud-container">
    <span class="span-header unselectable">Maak je eigen veld achtergrond</span>
    <!-- achtergrond -->
    <achtergrond-select />

    <!-- lijnenset -->
    <div class="accordion" id="select-container-lijnenset">
      <div class="accordion-row" v-on:click="toggleLijnenset('accordion-content-lijnenset')">
          <a class="accordion-button textOverflow">Lijnenset</a>
          <div class="accordion-icons">
            <font-awesome-icon id="chevron-down-icon-lijnenset" icon="chevron-down" />
            <font-awesome-icon id="chevron-up-icon-lijnenset" icon="chevron-up" class="hidden" />
          </div>
      </div>
      <div id="accordion-content-lijnenset" class="hidden accordion-content-lijnenset">
        <div class="accordion-div" v-for="lijn in lijnensets" v-bind:key="lijn.id">
          <a style="accordion-a" v-on:click="veranderLijnenset(lijn.url)">
            {{ lijn.naam }}
          </a>
        </div>
        <button class="verwijder-btn unselectable" v-on:click="veranderLijnenset('geen-lijnenset')">
          Verwijder lijnenset
        </button>
      </div>
    </div>

    <!-- veldindeling -->
    <div class="accordion" id="select-container-veldindeling">
      <div class="accordion-row" v-on:click="toggleVeldindeling('accordion-content-veldindeling')">
          <a class="accordion-button textOverflow">Veldindeling</a>
          <div class="accordion-icons">
            <font-awesome-icon id="chevron-down-icon-veldindeling" icon="chevron-down" />
            <font-awesome-icon id="chevron-up-icon-veldindeling" icon="chevron-up" class="hidden" />
          </div>
      </div>
      <div id="accordion-content-veldindeling" class="hidden accordion-content-veldindeling">
        <div class="accordion-div" v-for="indeling in indelingen" v-bind:key="indeling.id">
          <a style="accordion-a" v-on:click="veranderCanvasIndeling(indeling.url)">
            {{ indeling.naam }}
          </a>
        </div>
        <button class="verwijder-btn unselectable" v-on:click="veranderCanvasIndeling('geen-indeling')">
          Verwijder veldindeling
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { canvasInstantie } from "../../../business/canvas";
import { backgroundHandlerInstantie } from "../../../business/backgroundHandler";
import lijnensets from "../../../assets/lijnensets.json";
import indelingen from "../../../assets/indelingen.json";
import AchtergrondSelect from "./DropDowns/AchtergrondSelect";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add([faChevronUp, faChevronDown]);
export default {
  data() {
    return {
      canvas: null,
      lijnensets: lijnensets,
      indelingen: indelingen,
    };
  },
  components: {
    AchtergrondSelect,
    FontAwesomeIcon
  },
  methods: {
    toggleLijnenset(id) {
      let inklapbaarMenu = document.getElementById(id);
      let upIcon = document.getElementById("chevron-up-icon-lijnenset");
      let downIcon = document.getElementById("chevron-down-icon-lijnenset");
      let achtergrondSelectDiv = document.getElementById("select-container-lijnenset");

      upIcon.classList.toggle("hidden");
      downIcon.classList.toggle("hidden");
      inklapbaarMenu.classList.toggle("hidden");
      achtergrondSelectDiv.classList.toggle("achtergrond-select-active");
    },

    toggleVeldindeling(id) {
      let inklapbaarMenu = document.getElementById(id);
      let upIcon = document.getElementById("chevron-up-icon-veldindeling");
      let downIcon = document.getElementById("chevron-down-icon-veldindeling");
      let veldindelingSelectDiv = document.getElementById("select-container-veldindeling");

      upIcon.classList.toggle("hidden");
      downIcon.classList.toggle("hidden");
      inklapbaarMenu.classList.toggle("hidden");
      veldindelingSelectDiv.classList.toggle("achtergrond-select-active");
    },
    async veranderLijnenset(url) {
      if (url == "geen-lijnenset") {
        backgroundHandlerInstantie._lijnenset = "";
      } else {
        backgroundHandlerInstantie._lijnenset = `/img/lijnensetSvgs/${url}`
      }

      let dataUrl = await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte)
      canvasInstantie.veranderCellAchtergrondSvg(dataUrl);
    },
    async veranderCanvasIndeling(url) {
      if (url == "geen-indeling") {
        backgroundHandlerInstantie._indeling = "";
      } else {
        let xmlFile = await backgroundHandlerInstantie.fetchSvgAsXml(`/img/indelingSvgs/${url}`);
        backgroundHandlerInstantie._indeling = backgroundHandlerInstantie.getXmlTagContentAsString(xmlFile, "</svg>");
      }

      let dataUrl = await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte)
      canvasInstantie.veranderCellAchtergrondSvg(dataUrl);
    },
    veranderAchtergrondFoto(dataUrl) {
      canvasInstantie.veranderCellAchtergrondSvg(dataUrl);
    },
  },
};
</script>
<style scoped>
@import '/css/stapinhoud.css';
@import '/css/dropdown.css';


.accordion-content-lijnenset {
    max-height: 20rem;
    color: black;
    font-size: 0.8rem;
    background: white;
    padding: 0.3rem;
}


.accordion-content-veldindeling {
    max-height: 20rem;
    color: black;
    font-size: 0.8rem;
    background: white;
    padding: 0.3rem;
}

.achtergrond-select-active {
  border-color: #0180c6;
  color: rgb(1, 128, 198);
  padding-top: 0.5rem;
}

.achtergrond-select-active a b {
    color: #0180c6;
}



.verwijder-btn{
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  border: 0.1rem solid grey;
}


</style>
