import json from '../assets/activiteiten-voor-lessen.json';
export default class ActiviteitHandler {
    _activiteitenMetXml;
    _activiteitenZonderXml;

    _modulenamenMetXml;
    _modulenamenZonderXml;

    _alleActiviteiten;
    constructor() {
        this._alleActiviteiten = json.activiteiten;
        var object = this.sortJsonActiviteiten();
        this._activiteitenMetXml = object.xml;
        this._activiteitenZonderXml = object.geenXml;

        this._modulenamenMetXml = this.removeUnnecessaryModuleNamen(this._activiteitenMetXml, this.getAlleModuleNamen());
        this._modulenamenZonderXml = this.removeUnnecessaryModuleNamen(this._activiteitenZonderXml, this.getAlleModuleNamen());
    }

    /// <summary>
    ///	Filtert alle modulenamen uit de activiteiten json file
    /// </summary>
    /// <return>
    ///	Een array met alle modulenamen
    /// </return>
    getAlleModuleNamen() {
        var namen = [];
        json.activiteiten.forEach(element => {
            if (!namen.includes(element.modulenaam)){
                namen.push(element.modulenaam);
            }
        })
        return namen;
    }

    /// <summary>
    ///	sorteer alle json activiteiten op modulenaam en leeftijd
    /// </summary>
    /// <return>
    ///	een array met alle activiteiten gesorteerd op modulenaam en leeftijd
    /// </return>
    sortJsonActiviteiten(){
        var alleObjectenMetXml = [];
        var alleObjectenZonderXml = [];

        var objectenMetXmlZonderLesnaam = [];
        var objectenZonderXmlZonderLesnaam =[];

        var lesnaamObjectenMetXml = new Map();
        var lesnaamObjectenZonderXml = new Map();
        this.getAlleModuleNamen().forEach(naam => {
            json.activiteiten.forEach(element => {
                // check of modulenaam van element gelijk is aan huidige modulenaam
                if (element.modulenaam === naam){
                    // check of lesnaam niet null is anders object toevoegen
                    if (element.lesnaam != null) {
                        var key = element.modulenaam + '.' + element.lesnaam;
                        var lesnaamInMap;
                        // als er al een value is dan voeg het element toe anders maak nieuwe value in de map
                        if (element.xml != '' && element.xml != null && element.xml.includes("object")){
                            lesnaamInMap = lesnaamObjectenMetXml.get(key);
                            if (lesnaamInMap) {
                                lesnaamInMap.push(element);
                            } else {
                                lesnaamObjectenMetXml.set(key, [element]);
                            }
                        } else {
                            lesnaamInMap = lesnaamObjectenZonderXml.get(key);
                            if (lesnaamInMap) {
                                lesnaamInMap.push(element);
                            } else {
                                lesnaamObjectenZonderXml.set(key, [element]);
                            }
                        }
                    } else {
                        if (element.xml != '' && element.xml != null && element.xml.includes("object")){
                            objectenMetXmlZonderLesnaam.push(element);
                        } else {
                            objectenZonderXmlZonderLesnaam.push(element);
                        }
                    }
                }
            })
            // als er objecten met lesnamen zijn
            if (lesnaamObjectenMetXml.size > 0){
                lesnaamObjectenMetXml.forEach((value) => {
                    alleObjectenMetXml.push({ Id: this.objectIdCounter, Naam: naam, Lesnaam: value[0].lesnaam, Objecten: value});
                    this.objectIdCounter++;
                })
            }
            // als er losse objecten zijn zonder lesnaam
            if (objectenMetXmlZonderLesnaam.length > 0) {
                alleObjectenMetXml.push({ Id: this.objectIdCounter, Lesnaam: null, Naam: naam, Objecten: objectenMetXmlZonderLesnaam});
                this.objectIdCounter++;
            }

            // lege activiteiten:
            // als er objecten met lesnamen zijn
            if (lesnaamObjectenZonderXml.size > 0){
                lesnaamObjectenZonderXml.forEach((value) => {
                    alleObjectenZonderXml.push({ Id: this.objectIdCounter, Naam: naam, Lesnaam: value[0].lesnaam, Objecten: value});
                    this.objectIdCounter++;
                })
            }
            // als er losse objecten zijn zonder lesnaam
            if (objectenZonderXmlZonderLesnaam.length > 0) {
                alleObjectenZonderXml.push({ Id: this.objectIdCounter, Lesnaam: null, Naam: naam, Objecten: objectenZonderXmlZonderLesnaam});
                this.objectIdCounter++;
            }
            objectenMetXmlZonderLesnaam = [];
            objectenZonderXmlZonderLesnaam = [];
            lesnaamObjectenMetXml = new Map();
            lesnaamObjectenZonderXml = new Map();
        })

        // sorteer de lijsten op alfabetische volgorde
        alleObjectenMetXml.sort(function (a, b) {   
            return a.Naam.localeCompare(b.Naam) || a.Lesnaam.localeCompare(b.Lesnaam);
        });

        alleObjectenZonderXml.sort(function (a, b) {   
            return a.Naam.localeCompare(b.Naam) || a.Lesnaam.localeCompare(b.Lesnaam);
        });
        return {xml: alleObjectenMetXml, geenXml : alleObjectenZonderXml};
    }

    /// <summary>
    ///	haal onnodige modulenamen uit de modulenamen array
    /// </summary>
    /// <return>
    ///	een array met alle nodige modulenamen
    /// </return>
    removeUnnecessaryModuleNamen(activiteiten, modulenamen){
        var namenToBeRemoved = [];
        modulenamen.forEach(naam => {
            var bool = true;
            activiteiten.forEach(activiteit => {
                if (naam == activiteit.Naam){
                    bool = false;
                }
            })
            if (bool){
                namenToBeRemoved.push(naam);
            }
        })

        modulenamen = modulenamen.filter( el => !namenToBeRemoved.includes( el ) );

        return modulenamen;
    }
}
export const activiteitHandlerInstantie = new ActiviteitHandler();