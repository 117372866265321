export default class DropdownHandler {
    constructor() {
    }

    activeDropdown = {
        cat: '',
        arrowUp: '',
        arrowDown: '',
        content: '',
    }
    
    activeSubCat = {
        cat: '',
        arrowUp: '',
        arrowDown: '',
        content: '',
    }

    /// <summary>
    /// resetActiveMenu zet alle waarden van activeSubCat of activeDropdown naar ''.
    /// </summary>
    /// <param name='activeMenu'>activeMenu object waarvan waarden gereset worden. Kan allen activeSubCat of activeDropdown zijn</param>
    resetActiveMenu(activeMenu) {
        if (activeMenu != this.activeDropdown && activeMenu != this.activeSubCat) return;
        activeMenu.cat = '';
        activeMenu.arrowUp = '';
        activeMenu.arrowDown = '';
        activeMenu.content = '';
    }

    /// <summary>
    ///	schakelDropdown 'opent' en 'sluit' de dropdowns van het Stappenmenu
    /// </summary>
    /// <param name='id'>ID van dropdown element</param>
    schakelDropdown(id) {
        this.toggleDropdownClasses(this.activeDropdown)
        this.toggleDropdownClasses(this.activeSubCat)
        
        this.resetActiveMenu(this.activeSubCat)

        // Returned uit de functie en reset de activeDropdown, wanneer id overeenkomt met de activeDropdown
        if (this.activeDropdown.cat == ("cat-"+id)) {
            this.resetActiveMenu(this.activeDropdown)
            return
        }

        this.activeDropdown.cat = "cat-"+id;
        this.activeDropdown.arrowUp = "chevron-up-icon-"+id;
        this.activeDropdown.arrowDown = "chevron-down-icon-"+id;
        this.activeDropdown.content = "cat-content-"+id;
        
        this.toggleDropdownClasses(this.activeDropdown);
    }

    /// <summary>
    ///	schakelSubcategorie 'opent' en 'sluit' de subcategorieen binnen een dropdown
    /// </summary>
    /// <param name='id'>ID van dropdown element</param>
    schakelSubcategorie(id) {
        this.toggleDropdownClasses(this.activeSubCat)

        // Returned uit de functie en reset de activeSubCat, wanneer id overeenkomt met de activeSubCat
        if (this.activeSubCat.cat == ("subcat-"+id)) {
            this.resetActiveMenu(this.activeSubCat);
            return
        }

        this.activeSubCat.cat = "subcat-"+id;
        this.activeSubCat.arrowUp = "subcat-chevron-up-icon-"+id;
        this.activeSubCat.arrowDown = "subcat-chevron-down-icon-"+id;
        this.activeSubCat.content = "sub-content-"+id;
        
        this.toggleDropdownClasses(this.activeSubCat)
    }

    /// <summary>
    ///	toggleDropdownClasses schakelt de classes van de dropdowns en subcategorieen van active naar hidden en visa versa
    /// </summary>
    /// <param name='id'>ID van dropdown element</param>
    toggleDropdownClasses(id) {
        if (id.cat == '') return
        
        if (document.getElementById(id.cat) == null) {
            this.resetActiveMenu(this.activeSubCat);
            this.resetActiveMenu(this.activeDropdown);
            return
        }

        if (id.cat.includes("subcat-")){
            document.getElementById(id.cat).classList.toggle("subcat-active");
            document.getElementById(id.arrowUp).classList.toggle("hidden");
            document.getElementById(id.arrowDown).classList.toggle("hidden");
            document.getElementById(id.content).classList.toggle("subcat-show");
        } else {
            document.getElementById(id.cat).classList.toggle("active");
            document.getElementById(id.arrowUp).classList.toggle("hidden");
            document.getElementById(id.arrowDown).classList.toggle("hidden");
            document.getElementById(id.content).classList.toggle("show");
        }
    }
}