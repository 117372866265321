<template>
<div class="attribuutDiv" :name="divNaam" v-on:click="plaatsAttribuutBijKlik(item.naam)">
    <div class="attribuutAfbeelding" v-bind:style="{ 'background-image': 'url(' + getIcon(item.afbeeldingpad) + ')' }" :name="item.naam" :src="item.afbeeldingpad"></div>
    <span class="accordion-div-span">{{item.naam}}</span>
</div>
</template>

<script>
import {canvasInstantie} from "../../../business/canvas"
import {werkveldInstantie} from "../../../business/werkveld"

export default {

    props: ['item', 'zoek'],

    data() {
        return {
            divNaam: this.zoek + this.item.naam,
        }
    },

    mounted: function() {
        if (!("ontouchstart" in document.documentElement)) {
            var sleepbareDiv = document.getElementsByName(this.divNaam);
            werkveldInstantie.maakSleepbaar(sleepbareDiv, this.zoek);
        }
    },
    methods: {
        getIcon(url){
            return url && `/img/icons/${url}`
        },
        plaatsAttribuutBijKlik(naam){
            canvasInstantie.plaatsAttribuutBijKlik(naam);
        }
    }
}
</script>
<style scoped>

.attribuutAfbeelding{
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 33%;
    background-size: contain;
    z-index: 300 !important;
}

.attribuutDiv{
    display: flex;
    width: 100%;
    user-select: none;
    padding-bottom: 0.3rem;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.accordion-div-span {
    width: 64%;
    margin-left: 5px;
    font-size: 0.7rem;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
</style>