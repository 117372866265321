const mx = require('mxgraph')({
    mxBasePath: '/lib/mxgraph'
});

export default class StyleSheet {
    constructor(){

    }

    /// <summary>
    ///	Maakt een stijl voor een attribuut met een image
    /// </summary>
    /// <param name='image'>Afbeelding naam van het attribuut</param>
    /// <return>
    ///	Het stijl object.
    /// </return>
    getAttribuutStijl(image) {
        if (image == null){
            return;
        }
        var style = new Object();
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_IMAGE;
        style[mx.mxConstants.STYLE_EDITABLE] = 0;
        style[mx.mxConstants.STYLE_RESIZABLE] = 0;
        style[mx.mxConstants.STYLE_IMAGE] = `/img/icons/${image}`;
        return style;
    }

    /// <summary>
    ///	Maakt een stijl voor een beweging met een image
    /// </summary>
    /// <param name='image'>Afbeelding naam van het attribuut</param>
    /// <return>
    ///	Het stijl object.
    /// </return>
    getBewegingStijl(image) {
        if (image == null){
            return;
        }
        var style = new Object();
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_IMAGE;
        style[mx.mxConstants.STYLE_EDITABLE] = 0;
        style[mx.mxConstants.STYLE_ASPECT] = 'empty';
        style[mx.mxConstants.STYLE_RESIZABLE] = 1;
        style[mx.mxConstants.STYLE_IMAGE] = `/img/icons/${image}`;
        return style;
    }

    /// <summary>
    ///	Maakt een stijl voor een tekstvlak
    /// </summary>
    /// <return>
    ///	Het stijl object
    /// </return>
    getTekstVlakStijl(){
        var style = new Object();
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_IMAGE;
        style[mx.mxConstants.STYLE_FILLCOLOR] = null;
        return style;
    }
    /// <summary>
    ///	Maakt een stijl voor een lege activiteit
    /// </summary>
    /// <return>
    ///	Het stijl object
    /// </return>
    getLegeActiviteitStijl(){
        var style = new Object();
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_IMAGE;
        style[mx.mxConstants.STYLE_IMAGE] = '/img/icons/achtergrond-lege-activiteit.png';
        return style;
    }
    /// <summary>
    ///	Maakt een stijl voor een groep
    /// </summary>
    /// <return>
    ///	Het stijl object.
    /// </return>
    getGroepStijl() {
        var style = new Object();
        style[mx.mxConstants.STYLE_FILLCOLOR] = null;
        style[mx.mxConstants.STYLE_STROKECOLOR] = null;
        style[mx.mxConstants.STYLE_RESIZABLE] = 0;
        style[mx.mxConstants.STYLE_FOLDABLE] = 0;
        style[mx.mxConstants.STYLE_EDITABLE] = 0;
        return style;
    }
}