<template>
  <div class="canvas-container">
    <div class="canvas-inner-container">
      <div class="canvas" id="canvas"></div>
    </div>
  </div>
</template>

<script>
import { canvasInstantie } from "../../business/canvas";
import { backgroundHandlerInstantie } from "../../business/backgroundHandler";
import {xmlHandlerInstantie} from "../../business/xmlHandler";

export default {
  data() {
    return {};
  },
  async mounted() {
    var interval = setInterval(async function loadCanvas() {
      if (document.getElementById('canvas').getBoundingClientRect().width != 0){
        canvasInstantie.zetContainer(document.getElementById("canvas"));
        await xmlHandlerInstantie.openMetActiviteitXml();
        clearInterval(interval);
      }
    }, 10);

    this.$nextTick(function () {
      window.addEventListener("resize", this.updateCanvas);
    });

    if (!("ontouchstart" in document.documentElement)) {
      canvasInstantie.SetHandleSize(6)      
    } else {
      canvasInstantie.SetHandleSize(18) 
    }
  },
  methods: {
    async updateCanvas() {
      canvasInstantie.correctCellsToCanvas();
      canvasInstantie.zetGraphBounds();

        let updatedBackground = await backgroundHandlerInstantie.createCombinedBackground(backgroundHandlerInstantie._achtergrond, backgroundHandlerInstantie._lijnenset, backgroundHandlerInstantie._indeling, backgroundHandlerInstantie._grid, canvasInstantie._bladBreedte, canvasInstantie._bladHoogte)
        canvasInstantie.zetAchtergrond(updatedBackground);    
    }
  }
};
</script>

<style scoped>
.canvas-container {
  max-width: calc(100vh + 40px);
  margin: auto;
}

.canvas-inner-container {
  position: relative;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: #0180c6;
  width: 100%;
  padding-top: 75%;
  display: flex;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
